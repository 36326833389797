import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'

import { withFirebase } from '../Firebase';

class MoviesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      telugumovies: {},
      tamilmovies: {}
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    const currentuser = this.props.firebase.getUser();
    console.log("moviesUser", currentuser);


    this.props.firebase.movies().on('value', snapshot => {
      const moviesObject = snapshot.val();

      console.log("All Movies", moviesObject['Telugu']);
      this.setState({
        telugumovies: moviesObject['Telugu'],
        tamilmovies: moviesObject['Tamil'],
        loading: false
      })


    });

    this.props.firebase.users().on('value', snapshot => {
      console.log("usersdata", snapshot.val())
    })

  }

  componentWillUnmount() {
    this.props.firebase.movies().off();
  }

  render() {
    const { telugumovies, tamilmovies } = this.state;
    return (
      <Container style={{
        marginTop: 55
      }}>
        <div className="my-5">
          <h2>All Movies</h2>
        </div>
        <div class="ui five stackable cards">
          <MoviesList movies={tamilmovies} />
          <MoviesList movies={telugumovies} />
        </div>
      </Container>
    );
  }
}

const MoviesList = ({ movies }) => {
  var mkeys = Object.keys(movies);
  return (
    mkeys.map(mid => {
      console.log(mid);
      var { moviename, language, genre, imageurl, releasedate, rating } = movies[mid]

      return (
        <div class="ui raised card">
          <a class="image" href={`${language}/${moviename}/${mid}`}>
            <img class="ui small image" src={imageurl} alt={genre} />
          </a>
          <div class="center aligned content">
            <div>
              <h5 class="header" style={{ wordBreak: "break-word" }}>{moviename}</h5>
              <p class="meta">{releasedate}</p>
            </div>
            <div>
              <a class="ui red label left floated">{(rating * 20).toFixed(0)}</a>
              <a class="ui primary mini button right floated" href={`${language}/${moviename}/${mid}`}>
                <i class="star icon"></i> Rate Now
              </a>
            </div>
          </div>
        </div>
      )
    })
  )
};

export default withFirebase(MoviesPage);

