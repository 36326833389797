import React, { Component,useState ,useEffect} from 'react'
import { Feed, Button, Rating } from 'semantic-ui-react'
import { withFirebase } from '../Firebase'
import Infyscroll from './Infinitescroll';

class CommentList extends Component {
    constructor(props) {
        console.log("props in CommentsList", props)

        super(props);

        this.state = {
            reviews: {},
            loaded: false,
        };
    }

    componentDidMount() {
        this.setState({reviews : this.props.reviews})

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("statecheck",this.state)
        

        
    }

    

    // Displaylist = (reviews) => {

    //     return Object.keys(reviews).map((rid) => {
    //         var { comment, starrating, userName, selectedelements } = reviews[rid]
    //         // console.log(starrating)
    //         let elements = Object.keys(selectedelements).map(function (keyName) {
    //             return (selectedelements[keyName] === 1 ? [<Button size='mini' color='twitter'>{keyName}</Button>, ''] : '')
    //         })
    //         //Please style this component
    //         return (
    //             <>
    //                 <Feed size='large'>
    //                     <Feed.Event>
    //                         <Feed.Label image='https://i.pravatar.cc/150' />
    //                         <Feed.Content>
    //                             <Feed.Summary>
    //                                 <Feed.User>{userName}</Feed.User>
    //                                 <Feed.Date>Jan 27, 2020</Feed.Date>
    //                                 <br />
    //                                 <Rating defaultRating={starrating} maxRating={5} disabled />
    //                             </Feed.Summary>
    //                             <p>{comment}</p>
    //                             <Feed.Meta>
    //                                 {elements}
    //                             </Feed.Meta>
    //                         </Feed.Content>
    //                     </Feed.Event>
    //                 </Feed>
    //             </>)
    //     })
    // }


    render() {
        var { reviews } = this.state

        let currentUserReviewCount = this.props.currentUserReview == null ? 0 : 1


        let localreviewkeys = Object.keys(reviews).sort((key1, key2) => {
            let r1 = reviews[key1];
            let r2 = reviews[key2];
            return ((new Date(r1['timeStamp']) > new Date(r2['timeStamp'])) || -1)
        });

        console.log("no of revs",this.state, this.props)
        return (
            <div>
               
                {/* <SortFunction size={10} reviews={reviews} reviewkeys={localreviewkeys} />                 */}
                <Infyscroll size={10} reviews={reviews} reviewkeys={localreviewkeys} currentUserReviewCount={currentUserReviewCount}/>
            </div>
        )
    }
}


function SortFunction({reviews,reviewkeys}) {
    console.log("sort",reviews,reviewkeys)

    const [sortedreviewkeys, setsortedreviewkeys] = useState([])
    const [change ,setchange] = useState(true)
 
    useEffect(() => {
        setsortedreviewkeys(reviewkeys)
    }, [reviewkeys])

    const reverseKeys = () =>{
        setsortedreviewkeys([])
        setchange(!change)
    }
    
    return (
        <div>
            
            <Button onClick={reverseKeys}>Reverse </Button>
            <Infyscroll size={10} reviews={reviews} reviewkeys={sortedreviewkeys} change={change} />    
        </div>
    )
}


export default withFirebase(CommentList)