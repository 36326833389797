import React from 'react'
import { AuthUserContext, withAuthorization } from '../Session';
import { useState, useEffect } from 'react';

import { Divider, Feed, Segment, Icon } from 'semantic-ui-react'



function NotificationsPage(props) {

    // console.log("Notification Page", props)
    return (
        <div style={{ marginTop: '50px', padding: '3%' }}>
            <AuthUserContext.Consumer>
                {authUser => (
                    <>
                        <Notifications props={{ ...props, authUser }} />
                    </>
                )}
            </AuthUserContext.Consumer>
        </div>
    )
}


const Notifications = ({ props }) => {
    // console.log("Props in Notifications", props)

    const [notifications, setnotifications] = useState([])

    useEffect(() => {
        let rootRef = props.firebase.rootref();
        rootRef.child("users").child(props.authUser.uid).child('notifications').orderByKey().once('value', snapshot => {
            console.log("Notifs", snapshot.val())
            let notifs = []
            snapshot.forEach(child => {
                notifs.unshift({ ...child.val(), 'id': child.key })
                //marking the notifications in DB as seen 
                rootRef.child(`users/${props.authUser.uid}/notifications/${child.key}/isSeen`).set(1)
            })
            console.log("Final notifs", notifs)
            setnotifications(notifs)

        })
    }, [])

    const notifClicked = (e) => {
        const notif = notifications[e.target.id]

        if (notif['isOnclickEnabled'] === 'True' && notif['Type'] === 'OPEN_LINK') {
            window.open(notif['ExtraInfo']['onClickLink'], '_blank')?.focus()
        }

        if (notif['isOnclickEnabled'] === 'True' && notif['Type'] === 'OPEN_POPUP') {
            // In future if we want to display some popup on the same screen then we can use this
        }
    }

    const NotificationItem = ({ item, i }) => {
        // console.log("Notif Item", item, item.Title)
        return (
            <Feed.Event>
                <Feed.Label image={/* item['ExtraInfo']['imageUrl'] ? item['ExtraInfo']['imageUrl'] :  */ '/images/user_icon.png'} />
                <Feed.Content>
                    <Feed.Summary>
                        <Feed.User as="a" onClick={notifClicked} style={{ cursor: 'pointer', color: "black", fontWeight: 400 }} id={i} >{item.Title}</Feed.User>
                        <Feed.Date>{item.Timestamp}</Feed.Date>
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        )
    }

    return (
        <div>
            <h1 >Notifications <Icon name='bell' color='red' size='small' /></h1>
            <Divider />
            <Feed>
                {notifications.map((notif, i) => (
                    <>
                        <NotificationItem item={notif} i={i} />
                        <Divider />
                    </>
                )
                )}
            </Feed>
        </div>
    )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(NotificationsPage);