import React from 'react';

import { Form } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Form>
    <Form.Group inline>
      <a href="javascript:void(0)" onClick={firebase.doSignOut}>
        See You Later!
  </a>
    </Form.Group>
  </Form>
);

export default withFirebase(SignOutButton);