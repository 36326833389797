import React, { Component } from 'react';

import {
  Form
} from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: false,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    var result2 = passwordOne.match(/[0-9]/g);

    if (passwordOne.length < 3 || passwordOne.length > 15 || (!result2 || result2.length < 1)) {
      let errormsg = "Please make sure that the password length is 3-15 and should contain atleast 1 digit!! ";
      this.setState({
        errormsg,
        error: true
      })
      this.props.setErrorMsg(errormsg)
      return;
    }

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        console.log("passchanged")
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.setErrorMsg(null)
  };

  render() {
    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <>
        {/* {!error && <h1>{error.message}</h1>} */}
        <Form onSubmit={this.onSubmit}>
          <Form.Group inline>
            <Form.Field>
              <Form.Input name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="New Password" />
            </Form.Field>
            <Form.Field>
              <Form.Input name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Confirm New Password" />
            </Form.Field>
            <Form.Field>
              <Form.Button content='Submit' disabled={isInvalid} type="submit" />
            </Form.Field>
          </Form.Group>

        </Form>
      </>
    );
  }
}

export default withFirebase(PasswordChangeForm);