import React from 'react'
import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from './PasswordChange';
import SignOut from './SignOut';
import { useState, useEffect } from 'react';
import {
  Segment, Button,
  Form,
  Radio, Container, Header, Image, Modal, Label, Message, Grid, Tab, Divider, Feed, Rating, Dimmer, Icon
} from 'semantic-ui-react'
import Activity from './Activity';
import RenderReviews from '../minicomponents/renderReviews';


function ProfilePage(props) {
  const panes = [
    {
      menuItem: 'My Reviews',
      render: () => <MyReviews props={props}></MyReviews>,
    },
    {
      menuItem: 'Account Settings',
      render: () => <AccountSettings props={props}></AccountSettings>,
    },
  ]

  const [BioOpen, setBioOpen] = useState(false)
  const [QuoteOpen, setQuoteOpen] = useState(false)
  const [ImageOpen, setImageOpen] = useState(false)
  const [active, setActive] = useState(false)


  const [bio, setBio] = useState("")
  const [quote, setQuote] = useState("")
  const [image, setImage] = useState("")
  const [isQuoteErrMsg, setIsQuoteErrMsg] = useState(false)
  const [isBioErrMsg, setIsBioErrMsg] = useState(false)


  console.log("Profile", props)

  useEffect(() => {
    //load bio from user details
    let rootRef = props.firebase.rootref();
    console.log(props)
    rootRef.child("users").child(authUser.uid).on('value', snapshot => {
      setBio(snapshot.val().bio)
      setQuote(snapshot.val().quote)
    })
  }, [])

  const pushBio = (user) => {
    let bio = document.getElementById("updatebio").value
    let rootRef = props.firebase.rootref();
    if (bio.length <= 70) {
      console.log("Bioo Update", bio, user)
      rootRef.child("users").child(user.uid).child('bio').set(bio)
      setBioOpen(false)
      setIsBioErrMsg(false)
    }
    else {
      setIsBioErrMsg(true)
    }

  }

  const pushQuote = (user) => {
    let quote = document.getElementById("updatequote").value
    let rootRef = props.firebase.rootref();
    if (quote.length <= 90) {
      console.log("quote Update", quote, user)
      rootRef.child("users").child(user.uid).child('quote').set(quote)
      setQuoteOpen(false)
      setIsQuoteErrMsg(false)
    }
    else {
      setIsQuoteErrMsg(true)
    }
  }




  const UpdateBioPopup = ({ authUser }) => {

    return (
      <Modal
        size={'tiny'}
        closeIcon
        open={BioOpen}
        onClose={() => setBioOpen(false)}
        onOpen={() => setBioOpen(true)}
      >
        <Header content='Update your bio' />
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input name="Bio" id="updatebio" type="text" placeholder="Write a short Bio" />
            </Form.Field>
          </Form>
          {isBioErrMsg &&
            <Message negative onDismiss={() => setIsBioErrMsg(false)} header="Max length for bio is 70 caharacters only, please modify!" />}

        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => { pushBio(authUser) }}>Update</Button>
          <Button color='red' onClick={() => setBioOpen(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const UpdateQuotePopup = ({ authUser }) => {

    return (
      <Modal
        size={'tiny'}
        closeIcon
        open={QuoteOpen}
        onClose={() => setQuoteOpen(false)}
        onOpen={() => setQuoteOpen(true)}
      >
        <Header content='Update your Quote' />
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input name="Quote" id="updatequote" type="text" placeholder="Your favourite movie Quote" />
            </Form.Field>
          </Form>
          {isQuoteErrMsg &&
            <Message negative onDismiss={() => setIsQuoteErrMsg(false)} header="Max length for quote is 90 caharacters only, please modify!" />}

        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => { pushQuote(authUser) }}>Update</Button>
          <Button color='red' onClick={() => setQuoteOpen(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const UpdateImagePopup = ({ authUser }) => {

    return (
      <Modal
        size={'tiny'}
        closeIcon
        open={ImageOpen}
        onClose={() => setImageOpen(false)}
        onOpen={() => setImageOpen(true)}
      >
        <Header content='Update your Image' />
        <Modal.Content>
          <Form>

          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='green' onClick={() => { pushQuote(authUser) }}>Update</Button>
          <Button color='red' onClick={() => setImageOpen(false)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const content = <Button icon circular negative onClick={() => { setImageOpen(true) }}><Icon name="pencil" /></Button>

  const { authUser } = props
  return (
    <Container style={{ marginTop: "80px" }}>
      <Grid textAlign='center'>
        <Grid.Row centered stretched verticalAlign="middle">
          <Grid.Column textAlign="right" width={2} mobile={6} style={{ alignItems: "flex-end" }}>
            <Dimmer.Dimmable
              as={Image}
              dimmed={active}
              dimmer={{ active, content }}
              onMouseEnter={() => setActive(true)}
              onMouseLeave={() => setActive(false)}
              size="small"
              circular
              src={authUser.photoURL ? authUser.photoURL : '/images/user_icon.png'}
              alt="Profile Image"
            />
          </Grid.Column>
          <Grid.Column textAlign="left" width={9}>
            <Header as='h1'>
              {authUser.displayName}
              <Header.Subheader>
                <div style={{ display: "flex", alignItems: "center", gap: "5px", overflowWrap: "anywhere" }}>
                  <img src={`/images/bio.svg`} height="18px" alt="bio" style={{ alignSelf: "center" }} /> {bio}
                  <Icon name='pencil' size='small' style={{ color: "", cursor: "pointer" }} onClick={() => setBioOpen(true)} />
                  {/* <Header as='h6' style={{ color: "#018cc5", cursor: "pointer", margin: 0 }} onClick={() => setBioOpen(true)}>Edit</Header> */}
                </div>
              </Header.Subheader>
              <i style={{ display: "flex", alignItems: "center", gap: "5px", fontSize: "17px", fontWeight: 500, lineHeight: "20px" }}>
                <img src={`/images/quote.svg`} height="18px" alt="quote" style={{ alignSelf: "center" }} /> {quote}
                <Icon name='pencil' size='small' style={{ color: "", cursor: "pointer" }} onClick={() => setQuoteOpen(true)} />
                {/* <Header as='h6' style={{ color: "#018cc5", cursor: "pointer", margin: 0 }} onClick={() => setQuoteOpen(true)}>Edit</Header> */}
              </i>
            </Header>
            <UpdateBioPopup authUser={authUser} />
            <UpdateQuotePopup authUser={authUser} />
            <UpdateImagePopup authUser={authUser} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="bio-tab">
          <Tab menu={{ color: "red", text: true }} panes={panes} />
        </Grid.Row>
      </Grid>
    </Container>
  )

}

const AccountSettings = ({ props }) => {

  console.log(" AccountSettings", props)

  return (
    <Container style={{ marginTop: 55 }}>
      {/* {loading && <Lottieloading />} */}
      <AuthUserContext.Consumer>
        {authUser => (
          <>
            <UserAccount props={{ ...props, authUser }} />
          </>
        )}
      </AuthUserContext.Consumer>
    </Container>
  )
}

const UserAccount = ({ props }) => {
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)
  const authUser = props.authUser



  return (
    <>
      <Grid divided='vertically'>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as='h3'>Email</Header>
          </Grid.Column>
          <Grid.Column>
            <h4>{authUser.email}</h4>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as='h3'>Password Change</Header>
          </Grid.Column>
          <Grid.Column>
            <PasswordChangeForm setErrorMsg={setErrorMsg} />
            {errorMsg && <Label color="red">{errorMsg}</Label>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as='h3'>Account Delete</Header>
          </Grid.Column>
          <Grid.Column>
            <DelAcc user={authUser} props={props} setLoading={setLoading} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header as='h3'>Logout</Header>
          </Grid.Column>
          <Grid.Column>
            <SignOut />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column textAlign='right'>
            <a href="/terms" target="_blank" className="text-danger">Terms Conditions</a>
          </Grid.Column>
          <Grid.Column textAlign='left'>
            <a href="/privacy" target="_blank" className="text-danger">Privacy Policy</a>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const DelAcc = ({ user, props, setLoading }) => {

  const [isPopupOpen, setPopupOpen] = useState(false)
  const [deleteReason, setdeleteReason] = useState("")
  const [reasonValue, setReasonValue] = useState();
  const [errorMsg, setErrorMsg] = useState("You are about to delete your account from WattaMovie.com permanently.Your reviews will no longer be visible to others")

  const reasons = {
    '1': "Cant find my favourite movie ",
    '2': "Site is boring",
    '3': "Irrelevant and spam reviews",
    '4': "Other Reason"

  }


  const deleteuser = () => {
    let deletedUid = user.uid;
    console.log("user in delete", user, props.firebase.auth.currentUser, props.firebase.auth.currentUser)
    let rootRef = props.firebase.rootref();
    let feedback = document.getElementById("feed").value;
    props.firebase.auth.currentUser.delete().then(() => {

      rootRef.child("users").child(deletedUid).once("value", snapshot => {

        let { ReviewedMovies, email, username } = snapshot.val();
        //get the reviewed movies and for each reviewed movie process this review id as deleteReview() 
        //and store it in deleted reviews
        for (let mid in ReviewedMovies) {
          let rid = ReviewedMovies[mid].reviewid;
          rootRef.once("value", snapshot => {
            let language = snapshot.child("movies/Telugu").hasChild(mid) ? 'Telugu' : 'Tamil'
            let movie = snapshot.child("movies").child(language).child(mid).val();
            let reviewInfo = snapshot.child('AllReviews').child(rid).val();

            const deleteReviewWithId = (rid, uid, movie, reviewInfo) => {

              var { starrating, selectedelements } = reviewInfo;
              var { mid, language, totalvotes, rating, elements } = movie

              var updatedrating = totalvotes - 1 ? ((rating * totalvotes) - starrating) / (totalvotes - 1) : 0
              var updatedElements = elements

              console.log("afterdeleted deletedd", rating, totalvotes, starrating)
              Object.keys(elements).map((elem) => {
                updatedElements[elem] = elements[elem] - selectedelements[elem]
              })
              rootRef.child(`movies/${language}/${mid}/totalvotes`).set(totalvotes - 1)
              rootRef.child(`movies/${language}/${mid}/rating`).set(updatedrating)
              rootRef.child(`movies/${language}/${mid}/elements`).set(updatedElements)
              rootRef.child(`movies/${language}/${mid}/reviews/${uid}`).set(null)
              rootRef.child(`users/${uid}/ReviewedMovies/${mid}`).set(null)
              rootRef.child("deletedreviews").push(rid)
            }
            deleteReviewWithId(rid, deletedUid, movie, reviewInfo);
          })
        }


        rootRef.child(`users/${deletedUid}`).set(null)
        let box = { email, username, deleteReason, feedback };
        rootRef.child("deletedusers").child(deletedUid).set(box)

      })
    })
      .catch(function (error) {
        console.log("unable to delete account", error)
        setErrorMsg(error.message)
        if (error.code === "auth/requires-recent-login") {
          setLoading(true);
          setTimeout(() => { props.firebase.doSignOut() }, 3000)
        }
      });
  }

  const handleChange = (e, { value }) => {
    setReasonValue(value)
    setdeleteReason(reasons[value])
    setErrorMsg("")
  }

  const ConfirmDeletePopup = () => {
    return (
      <Modal closeIcon open={isPopupOpen} onClose={() => { setPopupOpen(false) }} >
        <Header icon='lock' content='Account Deletion' />
        <Modal.Content>

          {errorMsg ? <Message warning >{errorMsg}</Message> : <></>}
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Image size='medium' src='/images/missyou.png' />
              </Grid.Column>
              <Grid.Column width={10}>
                <Form>
                  <Label>Tell us why do you want to delete your account</Label>
                  <Form.Group grouped>
                    <Segment><Form.Field control={Radio} label={reasons['1']} value='1' checked={reasonValue === '1'} onChange={handleChange} /></Segment>
                    <Segment><Form.Field control={Radio} label={reasons['2']} value='2' checked={reasonValue === '2'} onChange={handleChange} /></Segment>
                    <Segment><Form.Field control={Radio} label={reasons['3']} value='3' checked={reasonValue === '3'} onChange={handleChange} /></Segment>
                    <Form.TextArea label='Other' id="feed" placeholder='Tell us more about it' />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid.Row>
            <Grid.Column width={16}>
            </Grid.Column>
          </Grid.Row>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { setPopupOpen(false) }}>Cancel</Button>
          <Button color="red" onClick={() => { deleteReason ? deleteuser() : setErrorMsg('Please select a reason') }}>Delete Account</Button>
        </Modal.Actions>
      </Modal>
    )

  }

  return (
    <Form>
      <Form.Group inline>
        <a href="#" value={"user"} onClick={(e) => { e.preventDefault(); setPopupOpen(!isPopupOpen) }}>Permanently Delete My Account</a>
      </Form.Group>
      <ConfirmDeletePopup />
    </Form>
  )
}

const MyReviews = ({ props }) => {

  const [userObj, setuserObj] = useState([])

  useEffect(() => {

    props.firebase.user(props.authUser.uid).once('value', snapshot => {
      console.log("userinfo", snapshot.val())
      setuserObj(snapshot.val())
    })

  }, [])

  return (
    <Container>
      {userObj.ReviewedMovies
        ? <>
          <RenderReviews firebase={props.firebase} userObj={userObj} />
        </>
        : <>
          <h2>You haven't reviewed any movies yet</h2>
          <a href="/"> Start Reviewing</a>
          <img src={`/images/noRevsFound.svg`} height="400px" />
        </>
      }

      {/* <ActivityTab firebase={props.firebase} authUser={props.authUser} ></ActivityTab> */}
    </Container>
  )
}

// const ActivityTab = ({ firebase, authUser }) => {

//   const [myReviews, setmyReviews] = useState([])




//   useEffect(() => {
//     setmyReviews([])
//   }, [])

//   useEffect(() => {

//       firebase.user(authUser.uid).once('value', snapshot => {
//           console.log("userinfo", snapshot.val())

//           let reviewedMovies = snapshot.val().ReviewedMovies;

//           for (let mid in reviewedMovies) {
//               let lang = reviewedMovies[mid]["Language"]
//               let rid = reviewedMovies[mid]["reviewid"]
//               firebase.movies().child(lang).child(mid).once('value', snapshot => {
//                   // console.log("moviee",snapshot.val())
//                   let movieInfo = snapshot.val();

//                   firebase.rootref().child("AllReviews").child(rid).once('value', snapshot => {
//                       let reviewInfo = snapshot.val()
//                       console.log("eachreview", rid, reviewInfo, movieInfo["moviename"])
//                       let eachReview = { ...reviewInfo, movieInfo }
//                       setmyReviews(myReviews => { return [...myReviews, eachReview] })
//                   })

//               })
//           }



//       })

//   }, [])

//   return (
//       <>
//           {myReviews.map(rev => {
//               var { comment, userName, selectedelements, starrating, timeStamp, movieInfo } = rev

//               return (
//                   <>
//                       <Reviewcard comment={comment} userName={userName} starrating={starrating} selectedelements={selectedelements} timeStamp={timeStamp} movieInfo={movieInfo} />
//                   </>
//               )
//           })}
//       </>)
// }

// const Reviewcard = ({ comment, starrating, selectedelements, timeStamp, movieInfo }) => {
//   let elements = Object.keys(selectedelements).map((keyName, i) => {
//       return (selectedelements[keyName] === 1 ? [<Label key={i} circular>{keyName}</Label>, ''] : '')
//   })

//   let { moviename, imageurl, language, mid } = movieInfo

//   return (
//       <Feed size='large'>
//           <Feed.Event>
//               <Feed.Label >
//                   <img src={imageurl} style={{ borderRadius: 0 }} />
//               </Feed.Label>
//               <Feed.Content>
//                   <Feed.Summary>
//                       <a target="_blank" href={`/${language}/${moviename}/${mid}`}><Feed.User>{moviename}</Feed.User></a>
//                       <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
//                       <br />
//                       <Rating defaultRating={starrating} maxRating={5} disabled />
//                   </Feed.Summary>
//                   <p>{comment}</p>
//                   <Feed.Meta className="movie-elements">
//                       {elements}
//                   </Feed.Meta>
//               </Feed.Content>
//           </Feed.Event>
//           <Divider />
//       </Feed>
//   )
// }

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProfilePage);



