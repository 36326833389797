import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';


const config = {
  apiKey: "AIzaSyCdIAf4zkZ9Ji2q5U6QhQUoFJ10ty41f6A",
  authDomain: "moviereview-ec863.firebaseapp.com",
  databaseURL: "https://moviereview-ec863.firebaseio.com",
  projectId: "moviereview-ec863",
  storageBucket: "moviereview-ec863.appspot.com",
  messagingSenderId: "88172446172",
  appId: "1:88172446172:web:7703786fc95073e3524f19",
  measurementId: "G-D1XR9X6CV5"
};

const config2 = {
  "apiKey": "AIzaSyCVAr6KCvQfT2hZIPOELP-9PdJceDd3AgU",
  "authDomain": "wattamovie-6c527.firebaseapp.com",
  "databaseURL": "https://wattamovie-6c527-default-rtdb.firebaseio.com/",
  "projectId": "wattamovie-6c527",
  "storageBucket": "wattamovie-6c527.appspot.com",
  "messagingSenderId": "301869111242",
  "appId": "1:301869111242:web:a3f92e30c889150ce1a133",
  "measurementId": "G-5BBSYJC0WZ"
};


class Firebase {
  constructor() {
    app.initializeApp(config2);
    // app.analytics();
    this.app = app
    this.auth = app.auth();
    this.db = app.database();
//  console.log("god",app,new app.auth.GoogleAuthProvider())
      // Get a reference to the storage service, which is used to create references in your storage bucket
    //  console.log("god",app,app.auth,app.auth())
    // Get a reference to the storage service, which is used to create references in your storage bucket
    this.storage = app.storage();
  }

  // *** Auth API ***
  setPersistence = (persistence) => {
    return this.auth.setPersistence(persistence)
  }

  firebaseAuth = () => this.auth

  firebaseApp = () =>{
    return this.app
    }

  doCreateUserWithEmailAndPassword = (email, password) => {
    console.log("insidefirebase", this)
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    console.log("signedout")
    this.auth.signOut()
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  getUser = () => {
    var user = this.auth.currentUser;
    console.log("in firebase", user)
    return user;
  }//this is not working when page is reloading so used context

  movie = mid => {
    return this.db.ref(`movies/${mid}`)
  };

  movies = () => this.db.ref('movies');

  tamilmovies = () => this.db.ref('movies/Tamil')

  telugumovies = () => this.db.ref('movies/Telugu')

  rootref = () => this.db.ref('/');

  storageRootRef = () => {
    return this.storage.ref();
  }

}




export default Firebase;