import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react'

export default function MenuAppBar() {
    return (
        <>
            <Container style={{
                marginTop: 55
            }}>
                <Grid divided='vertically'>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h1'>Privacy Policy</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <p>This Privacy Policy applies to information collected about you by WattaMovie.com. Please review this Privacy Policy carefully. By using WattaMovie.com, you acknowledge the data collection practices and purposes outlined in this Privacy Policy.</p>
                            <Header as='h2'>INFORMATION WE COLLECT</Header>
                            <ul>
                                <li>Personal Information such as Name and Email Address during Account Creation.</li>
                                <li>your interactions with WattaMovie(such as what you post, view, rate, and interact with movie content)</li>
                                <li>Also, personal information that may indirectly identify you(such as URL information, cookie data, and your IP address or MAC address)</li>
                            </ul>
                            <Header as='h2'>PURPOSE AND USES OF THE INFORMATION</Header>
                            <ul>
                                <li>Responding to your requests or inquiries, including technical support</li>
                                <li>Displaying reviews you post every time</li>
                                <li>Carrying out research and analytics for uses including improving the Site and developing new features</li>
                                <li>Sharing your reviews for promotional campaigns on social media channels and other public platforms.</li>
                                <li>Sending you emails regarding service updates, announcements, and other communications.</li>
                            </ul>
                            <p>Let's make this plain and simple, <b>We don't sell your data</b>.Rather we just use this information to improve your WattaMovie experience.</p>
                            <Header as='h2'>DELETING YOUR INFORMATION</Header>
                            <p>If you are a registered user of WattaMovie, you have the option to delete your account which is a permanent deletion. We will remove all of your reviews and account data. But we may retain certain information in our backup for a limited period for audit purposes.</p>
                            <p>From time to time, we may update this privacy policy. We will notify you about material changes in the way we treat personally identifiable information by placing a notice on our site or by sending you an email message. We encourage you to periodically check back and review this policy so that you know our current privacy practices.<br /><br />Updated on 08/01/2021</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
}