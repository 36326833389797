import React, { Component } from 'react';

import { Container, Grid, Header, Card, Icon, Menu, Segment, Image, Button, Label } from 'semantic-ui-react'

import { withFirebase } from '../Firebase';
import { Lottieloading } from '../minicomponents/loadingcomps'

// import { Spring } from "react-spring";



function FindPosition(oElement) {
  if (typeof (oElement.offsetParent) != "undefined") {
    for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
      posX += oElement.offsetLeft;
      posY += oElement.offsetTop;
    }
    return [posX, posY];
  }
  else {
    return [oElement.x, oElement.y];
  }
}

function GetCoordinates(e) {
  var myImg = document.getElementById("bannerimg");
  var PosX = 0;
  var PosY = 0;
  var ImgPos;
  ImgPos = FindPosition(myImg);
  if (!e) var e = window.event;
  if (e.pageX || e.pageY) {
    PosX = e.pageX;
    PosY = e.pageY;
  }
  else if (e.clientX || e.clientY) {
    PosX = e.clientX + document.body.scrollLeft
      + document.documentElement.scrollLeft;
    PosY = e.clientY + document.body.scrollTop
      + document.documentElement.scrollTop;
  }
  PosX = PosX - ImgPos[0];
  PosY = PosY - ImgPos[1];


  if (PosX > myImg.clientWidth / 2)
    window.location.href = "/Telugu/Vakeel%20Saab/-MXgmpnNbqNmlFedUQE2";
  else
    window.location.href = "/Tamil/Karnan/-MXgV_0trYuBW-SxGQh8";

  //  console.log("track",PosX,myImg.clientWidth)


}
// style={{position:"fixed",left:"10",bottom:"5"}}


class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      telugumovies: {},
      tamilmovies: {},
      banner:{img_link : '/images/homepage_banner.jpg'}
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.telugumovies().orderByChild('factor').limitToLast(10).on('value', snapshot => {
      let telugumovies = {}
      snapshot.forEach(child => {
        telugumovies[child.key] = child.val()
      });
      this.setState({
        telugumovies,
        loading: false
      })
    });

    this.props.firebase.tamilmovies().orderByChild('factor').limitToLast(10).on('value', snapshot => {
      let tamilmovies = {}
      snapshot.forEach(child => {
        tamilmovies[child.key] = child.val()
      });
      this.setState({
        tamilmovies,
        loading: false
      })
    
      this.props.firebase.rootref().child('website_settings').once('value',snapshot => {
        // console.log("Website Settings",snapshot.val())
        const banner = snapshot.val()['banner']['home_page']
        this.setState({banner})
      })

    });

  }

  componentWillUnmount() {
    this.props.firebase.movies().off();
  }

  render() {
    // console.log("Staet",this.state)
    const { telugumovies, loading, tamilmovies , banner } = this.state;
    return (
      <Container style={{ marginTop: 55 }}>
        <Image fluid src={banner.img_link} as='a' target='_blank' href={banner.redirect_link} />
        {/* <a href="https://cutt.ly/vjnK7GK"> */}
        {/* <Image fluid id='bannerimg' src='/images/home_banner.jpg' onClick={(e) => GetCoordinates(e)} style={{ cursor: 'pointer' }} /> */}
        {/* </a> */}
        <br />
        {/* <Grid verticalAlign='middle'>
          <Grid.Column floated='left' mobile={8} computer={5}>

            <Header as='h2' floated='left'>
              Newly Released
          </Header>
          </Grid.Column>
        </Grid>
        <br />
        <Card.Group itemsPerRow={5} doubling>
          <MoviesList movies={tamilmovies} loading={loading} size={5} firebase={this.props.firebase} />
        </Card.Group>
        <br />
        <br /> */}
        <Grid verticalAlign='middle'>
          <Grid.Column floated='left' mobile={8} computer={5}>

            <Header as='h2' floated='left'>
              Kollywood Movies
          </Header>
          </Grid.Column>
          <Grid.Column floated='right' mobile={8} computer={3}>
            <Header as='h3' floated='right'>
              <a href="/Tamil">View More +</a>
            </Header>
          </Grid.Column>
        </Grid>
        <br />
        <Card.Group itemsPerRow={5} doubling>
          <MoviesList movies={tamilmovies} loading={loading} size={5} firebase={this.props.firebase} />
        </Card.Group>
        <br />
        <br />
        <Grid verticalAlign='middle'>
          <Grid.Column floated='left' mobile={8} computer={5}>
            <Header as='h2' floated='left'>
              Tollywood Movies
          </Header>
          </Grid.Column>
          <Grid.Column floated='right' mobile={8} computer={3}>
            <Header as='h3' floated='right'>
              <a href="/Telugu">View More +</a>
            </Header>
          </Grid.Column>
        </Grid>
        <br />
        <Card.Group itemsPerRow={5} doubling>
          <MoviesList movies={telugumovies} loading={loading} size={5} firebase={this.props.firebase} />
        </Card.Group>
        <Menu text style={{ justifyContent: "center", padding: 50 }} stackable>
          <Menu.Item name='About Us' href="/" />
          <Menu.Item name='Terms & Conditions' href="/terms" />
          <Menu.Item name='Privacy Policy' href="/privacy" />
          <Menu.Item name='Feedback' href="/" />
          <Menu.Item name='Contact Us' href="/" />
        </Menu>
      </Container>
    );
  }
}

const MoviesList = ({ movies, loading, size, firebase }) => {
  var mkeys = Object.keys(movies);
  if (loading) return (
    <Lottieloading />
  )
  else
    return (
      mkeys.map((mid, index) => {

        var { moviename, language, genre, imageurl, releasedate, rating } = movies[mid]

        return (
          <>
            <Card key={index}>
              <a className="image" href={`${language}/${moviename}/${mid}`}>
                <Image src={imageurl} alt={genre} ui={false} />
              </a>
              <Card.Content textAlign="center">
                <Card.Header style={{ whiteSpace: "nowrap", height: "25px", overflow: "hidden", textOverflow: "ellipsis" }} title={moviename}>{moviename}</Card.Header>
                <Card.Description>{releasedate}</Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Label className="moviePercentage" style={{ backgroundColor: "#ff0000", color: "#fff", fontSize: "1.3rem", padding: ".5rem" }} size={"massive"} key={"red"}>
                  {`${(rating * 20).toFixed(0)}`}
                </Label>
                <a href={`${language}/${moviename}/${mid}`}>
                  <Header as='h5' style={{ textAlign: "right" }}>
                    <Icon color='yellow' name='star' />
                    Rate Now
                  </Header>
                </a>
              </Card.Content>
            </Card>
          </>
        )
      })
    )
};

export default withFirebase(Landing);

