import React from 'react'

import { Card, Icon, Image, Button, Label, Header } from 'semantic-ui-react'
export default function MoviesListinYear({ movies, selectedyear }) {
  var mkeys = Object.keys(movies).filter(key => { return (movies[key]['releasedate'].search(selectedyear) !== -1) });
  return (
    mkeys.map((mid, i) => {

      var { moviename, language, genre, imageurl, releasedate, rating } = movies[mid]

      return (
        <Card key={i}>
          <a className="image" href={`${language}/${moviename}/${mid}`}>
            <Image src={imageurl} alt={genre} ui={false} />
          </a>
          <Card.Content textAlign="center">
            <Card.Header style={{ whiteSpace: "nowrap", height: "25px", overflow: "hidden", textOverflow: "ellipsis" }} title={moviename}>{moviename}</Card.Header>
            <Card.Description>{releasedate}</Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Label className="moviePercentage" style={{ backgroundColor: "#ff0000", color: "#fff", fontSize: "1.3rem", padding: ".5rem" }} size={"massive"} key={"red"}>
              {`${(rating * 20).toFixed(0)}`}
            </Label>
            <a href={`${language}/${moviename}/${mid}`}>
              <Header as='h5' style={{ textAlign: "right" }}>
                <Icon color='yellow' name='star' />
                Rate Now
              </Header>
            </a>
          </Card.Content>
        </Card>
      )
    })
  )
};
