import React, { Component,useState,useEffect } from 'react';

import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';

import { Segment,Button,Checkbox,Form,Input,Radio,Select,Image,Message } from 'semantic-ui-react'

 
class AdminPage extends Component {
  constructor(props) {
    super(props);
    var x = "jja";
    this.state = {
      loading: false,
      users: [],
      x : x,
      totalreviews_count : 0,
      deletedreviews_count : 0
    };
  }
 
  componentDidMount() {
    this.setState({ loading: true });
    
    this.props.firebase.rootref().child('AllReviews').on('value',snapshot=>{
      this.setState({totalreviews_count : snapshot.numChildren()})
    })

    this.props.firebase.rootref().child('deletedreviews').on('value',snapshot=>{
      this.setState({deletedreviews_count : snapshot.numChildren()})
    })

    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      this.setState({
        users: usersList,
        loading: false,
      });
    });

    this.props.firebase.movies().on('value',snapshot=>{
      this.setState({allMovies : snapshot.val()})
    })

    


  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }
 
  render() {
    const { users } = this.state;

    

    return (
     
      <AuthUserContext.Consumer>
      {authUser => {
        
        if(authUser)
          {if(authUser.isAdmin)
            return( 
                  <div style={{margin:'5%'}}>
                    <h1>Admin </h1>
                    <h5>All reviews({this.state.totalreviews_count}) -&nbsp;Deleted Reviews({this.state.deletedreviews_count}) = {parseInt(this.state.totalreviews_count)-parseInt(this.state.deletedreviews_count)} </h5> 
                    <AddMovie firebase={this.props.firebase}/>
                    <Segment>
                      <MoviePinning firebase={this.props.firebase} allMovies={this.state.allMovies}/>
                    </Segment>
                    <Segment>
                      <PageConfigurations firebase={this.props.firebase} />
                    </Segment>
                    <Segment>
                      <h1>Notifications</h1>
                      <SendNotifications firebase={this.props.firebase} users={users}/>
                    </Segment>
                    <Segment style={{height: '300px',overflow:'scroll'}} >
                      <h3>Total no of users : {users.length}</h3> 
                      <UserList users={users} /> 
                    </Segment>
                    
                  </div>
                )
          else
            return(
              <div>
                {this.props.history.push("/")}
              </div>
            )
        }
      }}
      </AuthUserContext.Consumer>
    // 
    );
  }
}


const MoviePinning = ({firebase,allMovies}) =>{

  const [language, setlanguage] = useState("Telugu")
  const [pinnedMovies,setpinnedMovies] = useState([])
  const [pinCount,setpinCount] = useState(1)

  useEffect(() => {
    setpinnedMovies([])

  }, [language])

  const pinMovie = (e) =>{
    let mid = e.target.value
    console.log("selected",e.target.value,pinnedMovies,pinnedMovies.includes(mid))
    var newPinnedMovies = []
    if(pinnedMovies.includes(mid)){
      newPinnedMovies = pinnedMovies.filter(e => e !== mid)
    }
    else{
      newPinnedMovies = [...pinnedMovies,mid]
    }
    if(newPinnedMovies.length>10) newPinnedMovies.shift()
    setpinnedMovies(newPinnedMovies)

  }

  const pinThisMovies = (e) =>{
    let mid = e.target.value
    let tempPinnedMovies = pinnedMovies
    tempPinnedMovies[pinCount] = mid;
    setpinCount(pc=>pc+1)
    setpinnedMovies(tempPinnedMovies)
  }

  const clearSelection = () =>{
    setpinnedMovies([])
    setpinCount(1)
  }

  const savePinnedMovies = () =>{
    let allKeys = Object.keys(allMovies[language])
    for(let i in allKeys){
      let mid = allKeys[i]
      // console.log("pin",allMovies[language][mid],i,mid)

      firebase.movies().child(language).child(mid).child("factor").set(0)

    }
    var alpha = ['z','a','b','c','d','e','f','g','h','i','j']

    for(let i in pinnedMovies){
      let mid = pinnedMovies[i];
      firebase.movies().child(language).child(mid).child("factor").set(alpha[i])
    }
    console.log("firebasemovies",pinnedMovies)
    setpinnedMovies([])
    setpinCount(1)
  }

  return(
  <>
  <h3>Movie Pinning : Select the order of the movies to be appeared(only 10) <button onClick={()=>setlanguage("Tamil")}>Tamil</button> <button onClick={()=>setlanguage("Telugu")}>Telugu</button></h3> 
   <h1>Selected {pinCount-1}movies ...Now select movie for position : {pinCount}</h1> 
    {allMovies 
    ? (<>
      {Object.keys(allMovies[language]).map((mid,i)=>{
        // console.log("error",mid,allMovies[language][mid]["moviename"])
        return (
          
          <>
          <button  value={mid} style={{margin:"2px",color : (pinnedMovies.includes(mid) ? 'red' : 'blue')}} onClick={pinThisMovies}> {i}) {allMovies[language][mid]["moviename"]} </button>
          </>
        )
      })}
      <button onClick={savePinnedMovies}>Save</button>
      <button onClick={clearSelection}>Clear</button>
      <a>Save before proceeding to other language</a>
    </>
    ) 
    : (<h2>Loading</h2>)}
    
  
  </>)
}


const AddMovie = ({firebase}) =>{
  const mdata = {
    "totalvotes" : 0,
    "rating" : 0,
    "elements":{
        'Acting' : 0,
        'Story' : 0,
        'Songs' : 0,
        'Bgm' : 0,
        'Comedy' : 0,
        'Direction' : 0,
        'Cinematography' : 0,
        'Action' : 0,
        'Screenplay' : 0,
        'Nah' : 0
    }
}
  const [stateObj,setStateObj] = useState(mdata)

  const handleChange = (e) => {
    const newObj = { ...stateObj,[e.target.name]: e.target.value };
    // console.log("mynewob",newObj,{...stateObj})
    setStateObj(newObj); // Now it works
  }

  const handleClick =  () =>{
    stateObj['imageurl'] = `https://wattamovie.com/posters/${stateObj.language}/${stateObj.moviename}.jpg`
    var movieInfo = stateObj
    var key = firebase.movies().child(stateObj.language).push(stateObj).key
    firebase.movies().child(stateObj.language).child(key).child("mid").set(key)
    console.log("key",stateObj,key)

    // //send automated notification to all users
    // console.log("mmmm",stateObj,movieInfo)

    // // fetch the list of all users
    // var selectedUsers = ['DuP2sYjEf1em3BeCehh79CLnxgU2']
    // const epoch_time = Math.floor( new Date().getTime()/1000 )
    // const notify_object_template = {
    //   'Title' : 'Welcome to Wattamovie!! Reviews reinvented by YOU!!',
    //   'Timestamp' : Date().substr(4,20),
    //   'Type' : 'OPEN_LINK',
    //   'isOnclickEnabled' : "True",
    //   'ExtraInfo' : {
    //     'onClickLink' : 'https://wattamovie.com',
    //     'imageUrl' : 'https://picsum.photos/200'
    //   },
    //   'isSeen':0,
    //   'clickCounter' : 0,
    // }

    // const welcome_notify_object = {
    //   ...notify_object_template
    // }
    // // loop over the seleced users and send notifications to them
    // selectedUsers.forEach(uid => {
    //   firebase.rootref().child(`users/${uid}/notifications`).child(epoch_time).set(welcome_notify_object)
    // })

    document.getElementById("add_movie_form").reset();
  }

  return(
    <div>
      <h1>Add Movie</h1>
      <form id='add_movie_form'>
        <input type="text" placeholder='Moviename' name="moviename" onChange={handleChange}/>
        <input type="text" placeholder='Tamil/Telugu' name="language" onChange={handleChange}/>
        <input type="text" placeholder='Synopsis' name="synopsis" onChange={handleChange}/>
        <input type="text" placeholder='Genre' name="genre" onChange={handleChange}/>
        <input type="text" placeholder='Cast' name="cast" onChange={handleChange}/>
        <input type="text" placeholder='Direction' name="director" onChange={handleChange}/>
        <input type="text" placeholder='Story' name="storywriter" onChange={handleChange}/>
        <input type="text" placeholder='Music' name="music" onChange={handleChange}/>
        <input type="text" placeholder='Trailerid' name="trailerlink" onChange={handleChange}/>
        <input type="text" placeholder='Cinematography' name="cinematography" onChange={handleChange}/>
        <input type="text" placeholder='Example 22 December 2020' name="releasedate" onChange={handleChange}/>

        <button type="submit" onClick={handleClick}>submit</button>
      </form>
    </div>
  )
}


const UserList = ({ users }) => (
  <ol>
    {users.map((user,i) => (
      <li key={user.uid}>
        <span>
          <strong>E-Mail:</strong>  {user.email}
        </span>
        <span>
          <strong>Username:</strong> {user.username}
        </span>
      </li>
    ))}
  </ol>
);


const SendNotifications = ({firebase,users}) => {

  const [finalMessage, setfinalMessage] = useState('<Preview will appear here>')
  const [selectedUsers, setselectedUsers] = useState([])
  const [selectedOption, setselectedOption] = useState({})

  const message_options = [
    { key: '0', text: 'New Movie Notification', value: 'new_movie'},
    { key: '1', text: 'Custom Message', value: 'custom_msg'},
    { key: '2', text: 'Reminder Notification', value: 'reminder' },
  ]
  
  const message_type_details = {
    'new_movie' : {'type':'new_movie', actiontype : 'OPEN_LINK' , 'extra_info' : 'A new movie has been released' ,'placeholder':'Enter the movie name'}, 
    'custom_msg' : {'type':'custom_msg', actiontype : 'OPEN_LINK' ,'placeholder': 'Type the notification message here'},
    'reminder' : {'type':'reminder', actiontype : 'OPEN_LINK','extra_info' : "It's been a long time since you reviewed!" ,'placeholder' : '<Not Required>'}
  }

  const handleInputFields = (e) => {

    if(selectedOption['type'] == 'new_movie'){
      setfinalMessage(selectedOption['extra_info']+" : " + e.target.value)
    }
    if(selectedOption['type'] == 'custom_msg'){
      setfinalMessage(e.target.value)
    } 
    if(selectedOption['type'] == 'reminder'){
      setfinalMessage(selectedOption['extra_info'])
    } 
  }

  const handleDropdown = (e,result) => {
    setselectedOption(message_type_details[result.value])
  }

  const sendNotificationMessage = () => {
    // function to send the notifications
    const epoch_time = Math.floor( new Date().getTime()/1000 )
    const notify_object = {
      'Title' : finalMessage,
      'Timestamp' : Date().substr(4,20),
      'Type' : selectedOption['actiontype'],
      'isOnclickEnabled' : "True",
      'ExtraInfo' : {
        'onClickLink' : selectedOption['onClickLink'],
        'imageUrl' : 'https://picsum.photos/200'
      },
      'isSeen':0
    }

    // loop over the seleced users and send notifications to them
    selectedUsers.forEach((uid) => {
      firebase.rootref().child(`users/${uid}/notifications`).child(epoch_time).set(notify_object)
    })

    resetForm()
  }
  
  const resetForm = () => {
    setselectedUsers([])
    setselectedOption({})
    document.getElementById("notifForm").reset();
  }

  const toggleUser = (e) => {
    const uid = e.target.value
    const newSelectedUsers = selectedUsers.includes(uid) 
                       ? selectedUsers.filter(i => i !== uid) // remove uid
                       : [...selectedUsers,uid] // add uid
    setselectedUsers(newSelectedUsers)
  }
  
  const toggleAll = (e) => {
    e.target.checked 
    ? setselectedUsers(users.map(u => u.uid))
    : setselectedUsers([])
  }

  const {placeholder} = selectedOption
  return(
    <div>
      <Form id='notifForm'>
        <Form.Group widths='equal'>
          <Form.Field name='dropdown' control={Select} label='Message Type' options={message_options} 
            placeholder='Message Type' onChange={handleDropdown} />
          <Form.Field id='extrainput' control={Input} label={placeholder || 'Extra Input'} 
            placeholder={placeholder} onChange={handleInputFields} />
          <Form.Field id='onclicklink' control={Input} label='On Click Link' 
            placeholder='Notification redirection link'
            onChange={(e)=>{ setselectedOption({...selectedOption,'onClickLink': e.target.value}) }}/>
        </Form.Group>
        <Message
          header='Notification Preview'
          content={finalMessage}
        />
        <h4>Redirection Link : {selectedOption['onClickLink']}</h4>
      </Form>

      <h3>Total no of users : {users.length} | Selected Users : {selectedUsers.length}</h3>
      <input type='checkbox' name='select_all' onClick={toggleAll}/>
      <label> Select All</label>
      <div style={{height: '300px',overflow:'scroll'}}>
        <ol>
        {users.map((user) => (
          <li key={user.uid}>
            <input type='checkbox' id={user.uid} value={user.uid} onClick={toggleUser} checked={selectedUsers.includes(user.uid)}/>
            <span><strong> E-Mail: </strong>{user.email}</span>
            <span><strong>Username: </strong>{user.username}</span>   
          </li>
        ))}
        </ol>
      </div>
      <br/>    
      <Form.Field control={Button} onClick={sendNotificationMessage}>Submit</Form.Field>

    </div>
  )
}
 
const PageConfigurations = ({firebase}) => {

  const [HomeBanner, setHomeBanner] = useState({})
  const [TollywoodBanner, setTollywoodBanner] = useState({})
  const [KollywoodBanner, setKollywoodBanner] = useState({})



  const saveBannerData = (page_type,img_link,redirect_link) => {
    // save the link to the corresponding page (home/kolly/tolly)
    const banner_data = {img_link,redirect_link}
    // console.log("Banner Data Saved",page_type,banner_data)
    firebase.rootref().child(`website_settings/banner/${page_type}`).set({img_link,redirect_link})
    document.getElementById("bannerForm").reset();
  }
  
  return(
    <div>
      <h3>Website Settings</h3>
      <Form id='bannerForm'>
        <Form.Group widths='equal'>
          <Form.Field control={Input} placeholder='Home Banner Link here' 
            onChange={(e)=>setHomeBanner({...HomeBanner,'imgLink':e.target.value})} />
          <Form.Field control={Input} placeholder='Home Redirection Link here' 
            onChange={(e)=>setHomeBanner({...HomeBanner,'redirectLink':e.target.value})} />

          <Image size='large' wrapped  src={HomeBanner.imgLink} href={HomeBanner.redirectLink} target='_blank' as='a' />
          <Form.Field control={Button} onClick={()=>saveBannerData('home_page',HomeBanner.imgLink,HomeBanner.redirectLink)}>Save</Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field control={Input} placeholder='Kollywood Banner Link here' 
            onChange={(e)=>setKollywoodBanner({...KollywoodBanner,'imgLink':e.target.value})} />
          <Form.Field control={Input} placeholder='Kollywood Redirection Link here' 
            onChange={(e)=>setKollywoodBanner({...KollywoodBanner,'redirectLink':e.target.value})} />

          <Image size='large' wrapped  src={KollywoodBanner.imgLink} href={KollywoodBanner.redirectLink} target='_blank' as='a' />
          <Form.Field control={Button} onClick={()=>saveBannerData('kollywood_page',KollywoodBanner.imgLink,KollywoodBanner.redirectLink)}>Save</Form.Field>
        </Form.Group>

        <Form.Group widths='equal'>
          <Form.Field control={Input} placeholder='Tollywood Banner Link here' 
            onChange={(e)=>setTollywoodBanner({...TollywoodBanner,'imgLink':e.target.value})} />
          <Form.Field control={Input} placeholder='Tollywood Redirection Link here' 
            onChange={(e)=>setTollywoodBanner({...TollywoodBanner,'redirectLink':e.target.value})} />

          <Image size='large' wrapped  src={TollywoodBanner.imgLink} href={TollywoodBanner.redirectLink} target='_blank' as='a' />
          <Form.Field control={Button} onClick={()=>saveBannerData('tollywood_page',TollywoodBanner.imgLink,TollywoodBanner.redirectLink)}>Save</Form.Field>
        </Form.Group>

      </Form>
    </div>
  )
}
export default withFirebase(AdminPage);











// import React from 'react';
 
// import * as ROLES from '../../constants/roles';
// import { withAuthorization } from '../Session';
 
// const AdminPage = () => (
//   <div>
//     <h1>Admin</h1>
//     <p>
//       Restricted area! Only users with the admin role are authorized.
//     </p>
//   </div>
// );
 
// const condition = authUser =>
//   authUser && !!authUser.roles[ROLES.ADMIN];
 

// export default withAuthorization(condition)(AdminPage);
