import React, { Component } from 'react';

import { Container, Grid, Button, Form } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <Container style={{
    marginTop: 80
  }}>
    <Grid columns='equal' centered>
      <Grid.Column computer={6} tablet={8} mobile={16} className="middle aligned content">
        <PasswordForgetForm />
      </Grid.Column>
    </Grid>
  </Container>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Form onSubmit={this.onSubmit}>
        <h1>Password Forget</h1>
        <Form.Field>
          <label>Email</label>
          <input
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email Address"
          />
        </Form.Field>
        <Button disabled={isInvalid} type="submit" negative fluid>Reset My Password</Button>
        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <>
    <a className="text-dark" href={ROUTES.PASSWORD_FORGET}>Forgot Password?</a>
  </>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };