import React,{ Component,useState,useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import Navigation from '../Navigation/Navigation';
import LandingPage from '../Pages/Landing';
import SignUpPage from '../Pages/SignUp';
import SignInPage from '../Pages/SignIn';
import PasswordForgetPage from '../Pages/PasswordForget';
import AccountPage from '../Pages/Account';
import ActivityPage from '../Pages/Activity';
import TermsPage from '../Pages/Terms';
import PrivacyPage from '../Pages/Privacy';
import AdminPage from '../Admin';
import ProfilePage from '../Pages/Profile';

import { withAuthentication } from '../Session';
import MoviesPage from '../Pages/Movies';
import MoviePage from '../Pages/Movie';
import AddMovie from '../Admin/addmovie';
import TeluguMovies from '../Pages/TeluguMovies';
import TamilMovies from '../Pages/TamilMovies';
import PageNotFound from '../Pages/PageNotFound';
import UserPage from '../Pages/UserPage';
import NotificationsPage from '../Pages/Notifications';


const App = (props) => {

    const [notifCount, setNotifCount] = useState(0)
    useEffect(() => {
        // setTimeout(()=>{console.log("timer")},5)
        console.log("My user",props.firebase.auth.currentUser)
        if(props.firebase.auth.currentUser){
            let {uid} = props.firebase.auth.currentUser 
            props.firebase.rootref().child(`users/${uid}/notifications`)
            .orderByChild("isSeen").equalTo(0).on('value',snapshot => {
                console.log("Notif Count ",snapshot.numChildren())
            setNotifCount(snapshot.numChildren())
            })
        }
    }, [props])
    
    return (
        <div>
            <Navigation data = {{notifCount}}/>

            <Router>
                <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route exact path="/signup">
                        render ={props.firebase.auth.currentUser == null ? <SignUpPage /> : <Redirect to="/" />}
                    </Route>
                    <Route exact path="/signin">
                        render ={props.firebase.auth.currentUser == null ? <SignInPage /> : <Redirect to="/" />}
                    </Route>
                    <Route exact path="/Telugu" component={TeluguMovies} />
                    {/* <Route exact path="/Telugu">render = {<TeluguMovies deployMode = {DeployableMode} />} </Route> */}
                    <Route exact path="/Tamil" component={TamilMovies} />
                    {/* <Route exact path="/Tamil">render = {<TamilMovies deployMode = {DeployableMode}/>} </Route> */}
                    <Route exact path="/pw-forget" component={PasswordForgetPage} />
                    <Route exact path="/account" component={AccountPage} />
                    <Route exact path="/activity" component={ActivityPage} />
                    <Route exact path="/profile" component={ProfilePage} />
                    <Route exact path="/notifications" component={NotificationsPage} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy" component={PrivacyPage} />
                    <Route exact path="/admin" component={AdminPage} />
                    <Route exact path="/movies" component={MoviesPage} />
                    <Route exact path="/add" component={AddMovie} />
                    <Route exact path="/:language/:name/:mid" component={MoviePage} />
                    <Route exact path="/:username/:uid" component={UserPage} />
                    <Route path="*">
                        <PageNotFound />
                    </Route>
                </Switch>
            </Router>
        </div>
    )

};

export default withAuthentication(App);