import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Segment, Header, Divider, Feed, Rating, Label } from 'semantic-ui-react'

function Reviewcard({uid, comment, starrating, userName, selectedelements, timeStamp }) {
  let elements = Object.keys(selectedelements).map((keyName, i) => {
    return (selectedelements[keyName] === 1 ? [<Label key={i} circular className={keyName}><img src={`/images/${keyName}.svg`} alt={keyName}/>&nbsp;{keyName}</Label>, ''] : '')
  })
  return (
    <Feed size='large' >
      <Feed.Event>
        <Feed.Label image='/images/user_icon.png' />
        <Feed.Content>
          <Feed.Summary >
            
            <Feed.User onClick={(e)=>{window.open(`/${userName}/${uid}`,"_blank")}}>{userName[0].toUpperCase() + (userName.substring(1))}</Feed.User>
            <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
            <br /> 
            <Rating rating={starrating} maxRating={5} disabled />
          </Feed.Summary>
          <p>{comment}</p>
          <Feed.Meta className="movie-elements">
            {elements}
          </Feed.Meta>
        </Feed.Content>
      </Feed.Event>
      <Divider />
      {/* <Image src='/images/divider.svg' className="review-divider" fluid /> */}
    </Feed>
  )
}

class Infyscroll extends React.Component {
  state = {
    items: [],
    hasMore: true,
    currentsize: 0,
    sorting: "Oldest",
    rids : this.props.reviewkeys
  };

  componentDidMount() {
    
  }

  componentDidUpdate(a,b) {
    console.log("infyupdated", this.state, this.props,a,b)
    if(this.props.reviewkeys.length !== this.state.rids.length){
      this.setState({rids:this.props.reviewkeys})
    }

  }

  fetchMoreData = () => {
    
    // a fake async api call like which sends
    setTimeout(() => {
      console.log("fetcheee",this.state.items.length,this.state.hasMore)
    if (this.state.items.length >= this.props.reviewkeys.length) {
      console.log("fetchins",this.state.hasMore,this.state.items.length)
      this.setState({ hasMore: false });
      return;
    }


      var { currentsize,rids } = this.state
      this.setState({
        items: this.state.items.concat(rids.slice(currentsize, currentsize + 5)),
        currentsize: currentsize + 5
      });
    }, 100);
  };

  toggleReviews = (newtype) => {
    let {sorting,currentsize} = this.state;
    let rids = this.state.rids 

    if (newtype === sorting) return;
    // this.setState((prev) => { 
    //     let rev = [...prev.items].reverse(); 
    //     return { items: [], sorting: newtype , rids:rids } 
    //   })
      
    this.setState({sorting : newtype,currentsize:0,rids:rids.reverse()})
    this.clearItems();
  }

  clearItems = () =>{
    let {rids} = this.state
    let hasMore = false,size = rids.length
    if(rids.length > 5){
      hasMore = true;
      size = 5;
    }
    this.setState({items : [...rids.slice(0,size)],currentsize : size ,hasMore:hasMore})
    // console.log("fetcheeclear",this.state.items.length,rids)
    // this.fetchMoreData()


  }

  render() {
    return (
      <>
        <Segment clearing basic>
          <Header as='h2' floated='left'>User Reviews ({this.props.reviewkeys.length + this.props.currentUserReviewCount})</Header>
          <Header as='h3' floated='right'>
            <label className={"sorted" + ((this.state.sorting === "Latest") ? " active" : '')} onClick={() => this.toggleReviews("Latest")}>Latest &nbsp;</label>
            <label className={"sorted" + ((this.state.sorting === "Oldest") ? " active" : '')} onClick={() => this.toggleReviews("Oldest")}>Oldest</label>
          </Header>
        </Segment>
       

        <InfiniteScroll className="reviews"
          dataLength={this.state.items.length}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {this.state.items.map((rid) => {
            var { comment, userName, selectedelements, starrating, timeStamp ,uid } = this.props.reviews[rid];
            return <Reviewcard uid={uid} comment={comment} userName={userName} starrating={starrating} selectedelements={selectedelements} timeStamp={timeStamp} />
          })}
        </InfiniteScroll>
      </>
    );
  }
}

export default Infyscroll