import React from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  EmailIcon,
  WhatsappIcon,
  PinterestIcon
} from "react-share";
import { useState } from 'react';

export default function Social({content}) {
  
  console.log("urll", content);
  const [myurl] = useState(window.location.href)
  return (<div>
    <div style={{ position: "fixed", bottom: "50%", right: 0, zIndex: 3 }}>
      <div>
      <FacebookShareButton
        url={myurl}
        quote={`Checkout this amazing ${content.mname}.Im sure you will love this! Description:${content.desc}`}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton></div>

      <div>
      <TwitterShareButton title={`Checkout the reviews for ${content.mname} at @Watta_Movie . Start Reviewing `} url={myurl} >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      </div>

      <div>
        <PinterestShareButton title={`Checkout this amazing ${content.mname}.Im sure you will love this! Description:${content.desc}`} url={myurl}
          media="https://bit.ly/3g7wdkW">
          <PinterestIcon size={32} round={true} />
        </PinterestShareButton>
      </div>

      <div>
        <WhatsappShareButton title={`Checkout this amazing movie : ${content.mname}.\n Im sure you will love this! \n Description:${content.desc}`} url={myurl}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>

      <div>
        <EmailShareButton
          url={myurl}
          subject={`Watta Movie`}
          body={"Checkout this amazing movie!Im sure you will love this!"}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>


    </div>


  </div>)
}


function Facebook({content}) {
  const [myurl] = useState(window.location.href)
  return (

    <FacebookShareButton
      url={myurl}
      quote={`Checkout this amazing ${content.mname}.\nIm sure you will love this!\n Description:${content.desc}`}>
      <FacebookIcon size={32} round={true} />
    </FacebookShareButton>

  )
}

function Twitter({content}) {
  const [myurl] = useState(window.location.href)
  return (

    <TwitterShareButton title={`Checkout the reviews for ${content.mname} at @Watta_Movie . Start Reviewing `} url={myurl} >
      <TwitterIcon size={32} round={true} />
    </TwitterShareButton>

  )
}

function Whatsapp({content}) {
  const [myurl] = useState(window.location.href)
  return (

    <WhatsappShareButton title={`Checkout this amazing movie : ${content.mname}.\n Im sure you will love this! \n \n Description:${content.desc}`} url={myurl}>
      <WhatsappIcon size={32} round={true} />
    </WhatsappShareButton>

  )
}

function Email() {
  const [myurl] = useState(window.location.href)
  return (

    <EmailShareButton
      url={myurl}
      subject={`Watta Movie`}
      body={"Checkout this amazing movie!Im sure you will love this!"}>
      <EmailIcon size={32} round={true} />
    </EmailShareButton>

  )
}

export { Facebook, Whatsapp, Email, Twitter }