import React from 'react'
import { Button, Card, Placeholder } from 'semantic-ui-react'
import Lottie from 'react-lottie'
import star from '../lottiesanimations/40042-ratings.json'
import cameraload from '../lottiesanimations/cameraloader.json'

function LoadingCards() {
  return (
    // <div class="ui raised card">
    //   <a class="image">
    //     <img class="ui small image"  />
    //   </a>
    //   <div class="center aligned content">
    //     <div>
    //         <Placeholder.Line length='very short' />
    //         <Placeholder.Line length='medium' />
    //     </div>
    //     <div>

    //     </div>
    //   </div>
    // </div>
    <Placeholder>
      <Placeholder.Image square />
      <Placeholder.Line length='very short' />
      <Placeholder.Line length='medium' />
    </Placeholder>

  )
}

function LoadingText() {
  var cards = [1, 2, 3, 4, 5]
  return (
    <div>
      <Card.Group doubling itemsPerRow={5} stackable>
        {cards.map((card) => (
          <Card key={card.header}>

            <Placeholder>
              <Placeholder.Image square />
            </Placeholder>


            <Card.Content>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line length='very short' />
                  <Placeholder.Line length='medium' />
                </Placeholder.Header>
                <Placeholder.Paragraph>
                  <Placeholder.Line length='short' />
                </Placeholder.Paragraph>
              </Placeholder>

            </Card.Content>

            <Card.Content extra>
              <Button primary>
                Add
                </Button>
              <Button disabled={true}>Delete</Button>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    </div>


  )
}

function Lottieloading() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: cameraload,
  };

  return (
    <div style={{ position: "fixed", width: "100%", height: "100%", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(100,100,100,0.5)", zIndex: 2 }}>
      <Lottie options={defaultOptions}
        height={170}
        speed={2}
        style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      />
    </div>
  )
}

function Lottieloading2() {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: star,
  };

  return (
    <div style={{ position: "fixed", width: "100%", height: "100%", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(200,200,200,0.3)", zIndex: 2 }}>
      <Lottie options={defaultOptions}
        height={170}
        speed={1}
        style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
      />
    </div>
  )
}

export default LoadingCards
export { LoadingText, Lottieloading, Lottieloading2 }