import React from 'react'
import { useState, useEffect } from 'react';
import Reviewcard from './reviewcard';



const RenderReviews = ({firebase,userObj}) => {
     //This is a helper function that accepts user object and renders a list of reviews and the movie info under a user
    console.log("Renderr")
    const [myReviews, setmyReviews] = useState([])

    useEffect(() => {
      setmyReviews([])
    }, [])

    useEffect(() => {
        
        let reviewedMovies = userObj.ReviewedMovies;

        for (let mid in reviewedMovies) {
            let lang = reviewedMovies[mid]["Language"]
            let rid = reviewedMovies[mid]["reviewid"]
            firebase.movies().child(lang).child(mid).once('value', snapshot => {
                // console.log("moviee",snapshot.val())
                let movieInfo = snapshot.val();

                firebase.rootref().child("AllReviews").child(rid).once('value', snapshot => {
                    let reviewInfo = snapshot.val()
                    console.log("eachreview", rid, reviewInfo, movieInfo["moviename"])
                    let eachReview = { ...reviewInfo, movieInfo }
                    setmyReviews(myReviews => { return [...myReviews, eachReview] })
                })

            })
        }
        
    }, [userObj])
  
   
  
    return (
        <>
            {myReviews.map(rev => {
                var { comment, userName, selectedelements, starrating, timeStamp, movieInfo } = rev
                console.log("Userreviewsss",myReviews)
                return (
                    <>
                        <Reviewcard comment={comment} userName={userName} starrating={starrating} selectedelements={selectedelements} timeStamp={timeStamp} movieInfo={movieInfo} />
                    </>
                )
            })}
        </>)
  }


  export default RenderReviews;
