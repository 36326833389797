import React, { Component } from 'react';

import { Container, Segment, Message, Grid, Button, Header, Image, Modal, Icon, Rating, Feed, Form, TextArea, Embed, Label } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';
import { Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';
import CommentList from '../minicomponents/commentlist';
import { SignInForm } from './SignIn';
import Social, { Facebook, Whatsapp, Email, Twitter } from '../minicomponents/socialshare';
import NotVerifiedAlert from '../minicomponents/miscellaneous'
import { Lottieloading2 } from '../minicomponents/loadingcomps';


class MoviePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      movie: {},
      currentuser: undefined,
      mrating: 0,
      comment: "",
      reviews: {},
      isLoggedIn: false,
      isVerified: false,
      alreadyVoted: false,
      signinpopup: false,
      modal: false,
      notVerifiedpopup: false,
      reviewsuccesspopup: false,
      myreview: null,
      imgurl: "/images/homepage_banner.jpg",
      displayElements: ["Acting", "Story", "Songs", "Comedy", "Bgm", "Cinematography", "Screenplay", "Action", "Direction", "Nah"]
    };
  }

  static contextType = AuthUserContext

  componentDidMount() {
    this.setState({ loading: true });
    const { mid, language } = this.props.match.params

    const movieref = this.props.firebase.movies().child(language).child(mid)


    movieref.on('value', snapshot => {
      const movieObject = snapshot.val();
      let selectedElements = {}
      Object.keys(movieObject.elements).map((elem) => {
        selectedElements[elem] = false;
      })
      movieObject['mid'] = mid
      // console.log("Mooooovie", movieObject);

      this.setState({
        movie: movieObject,
        loading: false,
        selectedElements
      })
      this.renderridsInfo();
      if (this.props.firebase.auth.currentUser) {
        this.setState({
          isLoggedIn: true,
          isVerified: this.props.firebase.auth.currentUser.emailVerified || (this.props.firebase.auth.currentUser.providerData[0].providerId == "facebook.com")
        })
      }

      // var imagesref = this.props.firebase.storageRootRef();

      // imagesref.child(`MoviePosters/${language}/${movieObject.moviename}.jpg`).getDownloadURL()
      //   .then((url) => {
      //     this.setState({
      //       imgurl: url
      //     })
      //   })
      //   .catch((error) => {
      //     console.log("Imagee error", error)
      //   });
    });
  }

  componentDidUpdate() {
    var { currentuser } = this.state

    if (this.context && currentuser === undefined) {
      //to check if user is logged in and setting up the current user for further use
      var userRef = this.props.firebase.user(this.context.uid)
      userRef.on('value', snapshot => {
        var user = snapshot.val()
        var currentuser = { "uid": this.context.uid, ...user }
        var TorF = false
        if ('ReviewedMovies' in currentuser) {
          TorF = (this.state.movie.mid) in currentuser['ReviewedMovies']
        }
        this.setState({
          currentuser,
          alreadyVoted: TorF
        })
      })
    }
  }

  componentWillUnmount() {
    // this.props.firebase.movie().off();
  }


  //small functions

  deleteReview = () => {
    this.deleteRevtoggle()
    var { uid } = this.state.currentuser
    var { rid, reviewInfo } = this.state.myreview
    var rootRef = this.props.firebase.rootref()


    const deleteReviewWithId = (rid, uid, movie, reviewInfo) => {

      var { starrating, selectedelements } = reviewInfo;
      var { mid, language, totalvotes, rating, elements } = movie

      var updatedrating = totalvotes - 1 ? ((rating * totalvotes) - starrating) / (totalvotes - 1) : 0
      var updatedElements = elements

      Object.keys(elements).map((elem) => {
        updatedElements[elem] = elements[elem] - selectedelements[elem]
      })
      // console.log("afterdeleted", updatedElements, updatedrating, totalvotes - 1)
      rootRef.child(`movies/${language}/${mid}/totalvotes`).set(totalvotes - 1)
      rootRef.child(`movies/${language}/${mid}/rating`).set(updatedrating)
      rootRef.child(`movies/${language}/${mid}/elements`).set(updatedElements)
      rootRef.child(`movies/${language}/${mid}/reviews/${uid}`).set(null)
      rootRef.child(`users/${uid}/ReviewedMovies/${mid}`).set(null)
      rootRef.child("deletedreviews").push(rid)
    }

    deleteReviewWithId(rid, uid, this.state.movie, reviewInfo);




  }

  toggleClass = (option) => {
    if (option) {
      return "btn btn-primary"
    }
    else {
      return "btn btn-secondary"
    }
  }

  handleCheckBox = (e) => {
    var element = e.target.value;
    let selectedElements = this.state.selectedElements;
    selectedElements[element] = !selectedElements[element]
    this.setState({ selectedElements })
  }

  ratingChanged = (e, { rating }) => {
    this.setState({ mrating: rating })
  };

  checkIfFormValid = () => {

    var anyelemselected = false;
    var { mrating, selectedElements } = this.state

    //to check if atleast one element is selected or not
    for (var elem in selectedElements) {
      anyelemselected = selectedElements[elem] || anyelemselected
    }

    if (mrating === 0 || anyelemselected === false) {
      return false
    }
    else {
      return true
    }
  }

  displayErrorMsg = () => {
    this.setState({ "formError": true })
    setTimeout(
      function () {
        this.setState({ "formError": false });
      }
        .bind(this),
      3000
    );
  }

  submitReview = () => {
    var { currentuser } = this.state
    if (currentuser) {
      var isVerified = this.props.firebase.auth.currentUser.emailVerified || (this.props.firebase.auth.currentUser.providerData[0].providerId == "facebook.com")
      if (isVerified) {
        if (this.checkIfFormValid()) {
          this.pushReviewtoDB()
        }
        else {
          this.displayErrorMsg()
        }
      }
      else {
        this.setState({
          notVerifiedpopup: true
        })
        // console.log("You are not yet a verified user")
      }
    }
    else {
      //user not signed in
      this.setState({
        signinpopup: true
      })
      this.toggle()
    }

  }

  resetForm = () => {
  }

  pushReviewtoDB = () => {
    var { currentuser, movie, selectedElements, mrating, comment } = this.state
    const mymovie = movie
    var { username, uid } = currentuser
    var { mid, language, moviename } = mymovie

    var rootRef = this.props.firebase.rootref()

    rootRef.child(`movies/${language}/${mid}`).on("value", () => {
    })
    //create a comment object then push it and save that id in user/VotedMovies and also in movie/Reviews
    for (var elem in selectedElements) {
      selectedElements[elem] = 0 + selectedElements[elem];
    }
    var review = {
      "userName": username,
      "timeStamp": new Date().toString(),
      "mid": mid,
      "moviename": moviename,
      "starrating": mrating,
      "comment": comment.trim(),
      "selectedelements": selectedElements,
      "uid": uid
    }
    var reviewid = undefined;
    reviewid = rootRef.child("AllReviews").push(review).key

    var { elements, totalvotes, rating } = mymovie

    for (var elem in elements) {
      elements[elem] = elements[elem] + selectedElements[elem];
    }

    mymovie['totalvotes'] = totalvotes + 1;
    mymovie['elements'] = elements
    rating = ((rating * totalvotes) + mrating) / (totalvotes + 1)
    mymovie['rating'] = rating

    if ('reviews' in mymovie) {
      mymovie['reviews'][uid] = { "reviewid": reviewid, "timeStamp": Date.parse(new Date().toString()) }
    }
    else {
      var x = {}
      x[uid] = { "reviewid": reviewid, "timeStamp": Date.parse(new Date().toString()) }
      mymovie['reviews'] = x
    }

    rootRef.child(`movies/${language}/${mid}`).set(mymovie)
    rootRef.child(`users/${uid}/ReviewedMovies/${mid}`).set({ "reviewid": reviewid, "Language": language })

    //review posted
    this.togglesuccespopup()
    this.resetForm()
  }

  renderridsInfo = () => {
    var { movie } = this.state

    var moviereviews = movie["reviews"]
    var ReviewsRef = this.props.firebase.rootref().child("AllReviews")


    if (moviereviews) {
      Object.keys(moviereviews).map((uid) => {
        var rid = moviereviews[uid]["reviewid"]


        ReviewsRef.child(rid).on('value', (snapshot) => {
          var reviewInfo = snapshot.val()
          // console.log("reviewid", rid, reviewInfo)
          var rs = this.state.reviews
          if (this.context) {//if any user is logged in or not
            if (uid !== this.context.uid)//filtering the current users review
            {
              rs[rid] = reviewInfo
              this.setState({
                reviews: rs
              })
            }
            else {
              var myreview = { rid, reviewInfo }
              this.setState({
                myreview
              })
            }
          }
          else {//if no user is logged in
            rs[rid] = reviewInfo
            this.setState({
              reviews: rs
            })
          }
        })
      })
    }

  }

  sendVerificationmail = () => {

    var actionCodeSettings = {
      url: 'https://wattamovie.com'
    };

    this.props.firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(function () {
      // Email sent.
    }).catch(function () {
      // An error happened.\
    });
  }

  //toggles

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  deleteRevtoggle = () => {
    this.setState({
      deleteRevstate: !this.state.deleteRevstate
    })
  }

  notVerifiedtoggle = () => {
    this.setState({
      notVerifiedpopup: !this.state.notVerifiedpopup
    })
  }

  togglesuccespopup = () => {
    this.setState({
      reviewsuccesspopup: !this.state.reviewsuccesspopup
    })
  }

  ///popupsss

  SigninPopup = () => {
    return (
      <Modal closeIcon open={this.state.modal} size="tiny" onClose={this.toggle} >
        {/* <Header icon='lock' content='Sign in' /> */}
        <Modal.Content>
          <SignInForm prevloc={"/lol"} />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggle}> Cancel</Button>
          <Link to="/signup"><Button color="red" onClick={this.toggle}>Don't have an account? Signup</Button></Link>
        </Modal.Actions>
      </Modal>
    )

  }

  verifyEmailPopup = () => {
    if (this.props.firebase.auth.currentUser) {
      var useremail = this.props.firebase.auth.currentUser.email
      return (
        <Modal open={this.state.notVerifiedpopup} size="tiny" onClose={this.notVerifiedtoggle} >
          <Header icon='lock' content='Verify your email address!!' />
          <Modal.Content>
            Check your email : <b>{useremail}</b>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.notVerifiedtoggle}>Cancel</Button>
            <Button color="red" onClick={() => { this.sendVerificationmail(); this.notVerifiedtoggle() }}>Send Verification Mail</Button>
          </Modal.Actions>
        </Modal>
      )
    }
    else {
      return (<></>)
    }

  }

  deleteReviewPopup = () => {
    return (
      <Modal open={this.state.deleteRevstate} size="tiny" onClose={this.deleteRevtoggle} >
        <Header icon='lock' as="h3" content='Delete Review' />
        <Modal.Content>
          Are you sure you wish to delete this review? This will be deleted Permanently
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.deleteRevtoggle}>No</Button>
          <Button color="red" onClick={this.deleteReview}>Yes</Button>
        </Modal.Actions>
      </Modal>
    )
  }

  reviewSuccessPopup = ({ movie }) => {
    let content = { mname: movie.moviename, imgUrl: movie.imageurl, desc: movie.synopsis }
    return (
      <Modal closeIcon open={this.state.reviewsuccesspopup} size="tiny" centered onClose={this.togglesuccespopup} >
        <Modal.Content style={{ textAlign: "center" }}>
          <Image src='/images/review_popup.png' size='medium' centered />
          <h4>Share this</h4> <Facebook content={content} /> <Twitter content={content} /> <Email content={content} /> <Whatsapp content={content} />
        </Modal.Content>
      </Modal>
    )
  }

  //sub components

  UserReview = () => {
    if (this.state.myreview) {
      var { reviewInfo, rid } = this.state.myreview
      if (reviewInfo) {
        var { comment, starrating, userName, selectedelements, timeStamp } = reviewInfo
        let elements = Object.keys(selectedelements).map(function (keyName) {
          return (selectedelements[keyName] === 1 ? [<Label circular>{keyName}</Label>, ''] : '')
        })
        return (
          <>
            <Segment className="reviews">
              <Feed size='large'>
                <Feed.Event>
                  <Feed.Label image='/images/user_icon.png' />
                  <Feed.Content>
                    <Feed.Summary>
                      <Feed.User>{userName}</Feed.User>
                      <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
                      <Button color="red" icon floated="right" value={rid} onClick={this.deleteRevtoggle}><Icon name='trash alternate' /></Button>
                      <br />
                      <Rating defaultRating={starrating} maxRating={5} disabled />
                    </Feed.Summary>
                    <p>{comment}</p>
                    <Feed.Meta className="movie-elements">
                      {elements}
                    </Feed.Meta>
                  </Feed.Content>
                </Feed.Event>
              </Feed>
            </Segment>
          </>)
      }
    }
    else {
      return (<div>Loading Your Review...</div>)
    }

  }


  render() {
    const { movie, loading, selectedElements, mrating, formError, comment, alreadyVoted, displayElements } = this.state;
    return (
      <Container style={{
        marginTop: 55
      }}>

        {loading ? <Lottieloading2 /> :
          <div>
            <Social content={{ mname: movie.moviename, imgUrl: movie.imageurl, desc: movie.synopsis }} />
            <Segment>
              <Grid columns={2} stackable>
                <Grid.Column width={6} textAlign='center'>
                  <Image fluid rounded src={movie.imageurl} alt={movie.genre} />
                  <Header as='h2'>
                    {movie.moviename}
                  </Header>
                  <Label className="moviePercentage" style={{ backgroundColor: "#ff0000", color: "#fff", fontSize: "2.3rem", padding: ".7rem 1.2rem" }} size={"massive"} key={"red"}>
                    {(movie.rating * 20).toFixed(0)}
                  </Label>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Header as='h2'>
                    Synopsis
                  </Header>
                  <p>{movie.synopsis}</p>
                  <p><b>Language:</b> {movie.language}</p>
                  <p><b>Genre:</b> {movie.genre}</p>
                  <p><b>Director:</b> {movie.director}</p>
                  <p><b>Cast:</b> {movie.cast}</p>
                  <p><b>Story Writer:</b> {movie.storywriter}</p>
                  <p><b>Music:</b> {movie.music}</p>
                  <p><b>Cinematography:</b> {movie.cinematography}</p>
                  <p><b>Release Date:</b> {movie.releasedate}</p>
                  <Modal closeIcon
                    trigger={<Button primary basic className="trailer"><Icon name='play circle' />Watch Trailer</Button>}
                    content={
                      <Embed
                        active
                        id={movie.trailerlink}
                        source='youtube'
                      />} />
                  <br /><br />
                  <div className="movie-elements">
                    {displayElements.map((key, i) => <Label key={i} className={key} size={"large"}>{key.charAt(0).toUpperCase() + key.slice(1)}&nbsp;&nbsp;&nbsp;<span>{movie.elements[key]} Votes</span></Label>)}
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
            <NotVerifiedAlert isVerified={this.state.isVerified} isLoggedIn={this.state.isLoggedIn} />
            {alreadyVoted //&& this.state.myreview && this.state.myreview.reviewInfo 
              ? (<div>
                {this.UserReview()}
              </div>)
              :
              <Segment>
                <Header as='h2'>
                  Rate Here!
                </Header>
                <Form>
                  <Rating defaultRating={mrating} maxRating={5} size='massive' onRate={this.ratingChanged} /> &nbsp;&nbsp;
                  <Button.Group compact>

                    {displayElements.map((element) => (
                      <Button compact style={{ margin: "1px" }} color={selectedElements[element] ? 'red' : undefined}
                        key={element}
                        onClick={() => { this.setState(prev => { prev.selectedElements[element] = !prev.selectedElements[element]; return { selectedElements: prev.selectedElements } }) }}>
                        <img src={`/images/${element}.svg`} height="18px" alt={element} />&nbsp;&nbsp;{(element === "Bgm") ? "BGM" : element}
                      </Button>
                    ))}
                  </Button.Group><br /><br />
                  <TextArea onChange={(e) => { this.setState({ "comment": e.target.value }) }} value={comment} placeholder="Share your experience (Optional)" /><br /><br />
                  {formError && <Message color='red'><p>Please select atleast 1 element and give the star rating</p></Message>}
                  <Button color="red" size="big" floated='right' disabled={new Date(movie.releasedate) > new Date()} onClick={this.submitReview}>Post</Button><br /><br />
                </Form>
              </Segment>
            }
            <div>
              {movie.reviews
                ? <>
                  <CommentList reviews={this.state.reviews} rids={movie.reviews} currentUserReview={this.state.myreview} />
                </>
                : <div style={{ textAlign: "center" }}>
                  {new Date(movie.releasedate) > new Date()
                    ? <>
                      {/* <h2>Movie not yet released</h2> */}
                      <Image src={`/images/upcomingMovie.png`} style={{ margin: "0 auto" }} />
                    </>//This is if the movie is not yet released and has no reviews
                    : <>
                      {/* <h2>No Reviews Found</h2> */}
                      <Image src={`/images/noRevsFound.png`} style={{ margin: "0 auto" }} />
                      {/* <h3>Be the first one to review</h3> */}
                    </>//This is if the movie is released but has no reviews yet
                  }
                </div>
              }

            </div>
          </div>}

        <this.SigninPopup />
        <this.verifyEmailPopup />
        <this.deleteReviewPopup />
        <this.reviewSuccessPopup movie={movie} />
      </Container>
    );
  }
}




export default withFirebase(MoviePage);
