import React from 'react'

export default function NotVerifiedAlert({ isVerified, isLoggedIn }) {

  if (isLoggedIn && !isVerified) return (

    <div class="ui message"><p>Please Verify your email to review!!</p></div>

  )
  return null
}


// isLoggedIn  isVerified    DISPLAY
// T            T            F
//  T           F            T
//  F          T             F
//  F          F             F
