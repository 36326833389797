import React from 'react'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react';

import { withAuthorization } from '../Session';
import {
    Container, Header, Label, Grid, Divider, Feed, Rating, Image, Tab, Menu
} from 'semantic-ui-react'
import RenderReviews from '../minicomponents/renderReviews';

function UserPage(props) {

    let { username, uid } = useParams()
    const [user, setUser] = useState({})

    useEffect(() => {

        props.firebase.user(uid).on('value', snapshot => {
            setUser(snapshot.val())
        })

    }, [props.firebase])
    const panes = [
        {
            menuItem: <Menu.Item key='messages'>
                Reviews<Label color="red">{(user.ReviewedMovies && Object.keys(user.ReviewedMovies).length) ?? 0}</Label>
            </Menu.Item>
        },
    ]

    return (
        <>
            <Container style={{ marginTop: "80px" }}>
                <Grid textAlign='center'>
                    <Grid.Row centered stretched verticalAlign="middle">
                        <Grid.Column textAlign="right" width={2} mobile={6} style={{ alignItems: "flex-end" }}>
                            <Image src={user.photoURL ? user.photoURL : '/images/user_icon.png'} size='small' circular />
                        </Grid.Column>
                        <Grid.Column textAlign="left" width={9}>
                            <Header as='h1'>
                                {username}
                                <Header.Subheader>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px", overflowWrap: "anywhere" }}>
                                        <img src={`/images/bio.svg`} height="18px" alt="bio" style={{ alignSelf: "center" }} /> {user.bio}
                                    </div>
                                </Header.Subheader>
                                <i style={{ display: "flex", alignItems: "center", gap: "5px", fontSize: "17px", fontWeight: 500 }}>
                                    <img src={`/images/quote.svg`} height="18px" alt="quote" style={{ alignSelf: "center" }} /> {user.quote}
                                </i>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="bio-tab">
                        <Tab menu={{ color: "red", text: true }} panes={panes} />
                    </Grid.Row>
                    <Grid.Row className="bio-tab">{
                        user.ReviewedMovies
                            ? <>
                                <RenderReviews firebase={props.firebase} userObj={user} />
                            </>
                            : <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <img src={`/images/UserNoRevs.svg`} height="200px" />
                                <Header as='h2' image>
                                    {user.username} hasn't reviewed any movies
                                </Header>
                            </div>
                    }
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    )
}

// const ActivityTab = ({ firebase, user }) => {

//     const [myReviews, setmyReviews] = useState([])

//     useEffect(() => {
//         console.log("myrevs", myReviews);
//     }, [myReviews])

//     useEffect(() => {
//         // console.log("userinfo", user)

//             let reviewedMovies = user.ReviewedMovies;

//             for (let mid in reviewedMovies) {
//                 let lang = reviewedMovies[mid]["Language"]
//                 let rid = reviewedMovies[mid]["reviewid"]
//                 firebase.movies().child(lang).child(mid).once('value', snapshot => {
//                     let movieInfo = snapshot.val();

//                     firebase.rootref().child("AllReviews").child(rid).once('value', snapshot => {
//                         let reviewInfo = snapshot.val()
//                         console.log("eachreview", rid, reviewInfo, movieInfo["moviename"])
//                         let eachReview = { ...reviewInfo, movieInfo }
//                         setmyReviews(myReviews => { return [...myReviews, eachReview] })
//                     })

//                 })
//             }

//     }, [user])

//     return (
//         <>
//             {myReviews.map(rev => {
//                 var { comment, userName, selectedelements, starrating, timeStamp, movieInfo } = rev

//                 return (
//                     <>
//                         <Reviewcard comment={comment} userName={userName} starrating={starrating} selectedelements={selectedelements} timeStamp={timeStamp} movieInfo={movieInfo} />
//                     </>
//                 )
//             })}
//         </>)
// }

// const Reviewcard = ({ comment, starrating, selectedelements, timeStamp, movieInfo }) => {
//     let elements = Object.keys(selectedelements).map((keyName, i) => {
//         return (selectedelements[keyName] === 1 ? [<Label key={i} circular>{keyName}</Label>, ''] : '')
//     })

//     let { moviename, imageurl, language, mid } = movieInfo

//     return (
//         <Feed size='large'>
//             <Feed.Event>
//                 <Feed.Label >
//                     <img src={imageurl} style={{ borderRadius: 0 }} />
//                 </Feed.Label>
//                 <Feed.Content>
//                     <Feed.Summary>
//                         <a target="_blank" href={`/${language}/${moviename}/${mid}`}><Feed.User>{moviename}</Feed.User></a>
//                         <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
//                         <br />
//                         <Rating defaultRating={starrating} maxRating={5} disabled />
//                     </Feed.Summary>
//                     <p>{comment}</p>
//                     <Feed.Meta className="movie-elements">
//                         {elements}
//                     </Feed.Meta>
//                 </Feed.Content>
//             </Feed.Event>
//             <Divider />
//         </Feed>
//     )
// }


const conditionForViewing = authUser => true; //true : can access without logging in // !!authUser : for only logged in users

export default withAuthorization(conditionForViewing)(UserPage);