import React, { Component } from 'react';

import { Container, Grid, Card, Divider, Segment, Header, Button, Form, Image, Message } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';


import { withFirebase } from '../Firebase';

import * as ROUTES from '../../constants/routes';


const SignUpPage = () => (

  <Container style={{
    marginTop: 80
  }}>
    <Grid columns='equal'>
      <Grid.Column only='tablet computer'>
        <Image size="medium" src='/images/signup_popup.svg' style={{ margin: "0 auto" }} />
      </Grid.Column>
      <Grid.Column className="middle aligned content">
        <SignUpForm />
        <Segment clearing basic>
          <Header as='h5' floated='left'>
            <a className="text-dark" href="/signin">I am already a member</a>
          </Header>
        </Segment>
      </Grid.Column>
    </Grid>
  </Container>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  errormsg: ""
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;

    var usernamepatt1 = /[a-zA-Z0-9]/g; //pattern for checking username with char and nums
    var result = username.match(usernamepatt1);

    if (username.length < 3 || username.length > 15 || result.length !== username.length) {
      //check if username has length btwn 3-15 and contains only alphabets and numbers
      this.setState({
        errormsg: "Please make sure that the username length is 3-15 and can contain only alphabets and digits!! ",
        error: true
      })
      return;
    }

    var result2 = passwordOne.match(/[0-9]/g);

    if (passwordOne.length < 3 || passwordOne.length > 15 || (!result2 || result2.length < 1)) {
      this.setState({
        errormsg: "Please make sure that the password length is 3-15 and should contain atleast 1 digit!! ",
        error: true
      })
      return;
    }



    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {

        this.props.firebase.auth.currentUser.updateProfile({
          displayName: username,
        })
        var actionCodeSettings = {
          url: 'https://wattamovie.com'
        };
        this.props.firebase.auth.currentUser.sendEmailVerification(actionCodeSettings).then(function () {
        }).catch(function () {
          // console.log("errorrr", error);
        });
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        // console.log("errorrr", error)
        this.setState({ error: error, errormsg: error.message });
      });

    event.preventDefault();

  }

  googleSignIn = (event) => {

    var app = this.props.firebase.firebaseApp();
    var auth = this.props.firebase.firebaseAuth()
    var provider = new app.auth.GoogleAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    auth.signInWithPopup(provider).then((result) => {
      var token = result.credential.accessToken;
      var user = result.user;
    
      this.props.firebase.user(user.uid).on('value',snapshot=>{
        let myuser = snapshot.val();
        console.log("signedddinn",myuser)

        if(myuser){
          //user already created..this is just login
          this.props.history.push("/");
        }
        else{
          const epoch_time = Math.floor( new Date().getTime()/1000 );
          const welcome_notify_object = {
            'Title' : ` "Reviews Reinvented" - Welcome to Watta Movie ${user.displayName} :)`,
            'Timestamp' : Date().substr(4,20),
            'Type' : 'OPEN_LINK',
            'isOnclickEnabled' : "True",
            'ExtraInfo' : {
              'onClickLink' : 'https://wattamovie.com',
              'imageUrl' : 'https://picsum.photos/200'
            },
            'isSeen':0,
            'clickCounter' : 0,
          }

          const update_bio_notify_object = {
            ...welcome_notify_object,
            'Title' : `Your BIO section is waiting to get filled. Click here to add your favourite movie catchprase, manage your reviews & more`,
            'ExtraInfo' : {
              'onClickLink' : 'https://wattamovie.com/profile',
              'imageUrl' : 'https://picsum.photos/200'
            },            
          }
          console.log('creating user in DB')
          //user is not existing..so create a user in our db
          this.props.firebase
                .user(user.uid)
                .set({
                  username : user.displayName,
                  email : user.email,
                  photoUrl : user.photoURL,
                  type: "googlelogin",
                  fromPage : 'signup',
                  notifications : {
                    [epoch_time] : welcome_notify_object,
                    [epoch_time + 10] : update_bio_notify_object
                  }
                  
                }).then((u)=>{
                  // console.log("pushing success",u)
                }).catch((error)=>{
                  console.log("pushing error",error)
                })
          console.log("Created user")      
          this.props.history.push("/");
        }
      })
    }).catch(function (error) {
      console.log("error in google", error)
    });
  }

  facebookSignIn = (event) => {

    var app = this.props.firebase.firebaseApp();
    var auth = this.props.firebase.firebaseAuth()
    var provider = new app.auth.FacebookAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    auth.signInWithPopup(provider).then((result) => {
      var token = result.credential.accessToken;
      var user = result.user;
      console.log("facebook",user,user.providerData[0].providerId)

      this.props.firebase.user(user.uid).on('value',snapshot=>{
        let myuser = snapshot.val();
        console.log("signedddinn face",myuser)

        if(myuser){
          //user already created..this is just login
          this.props.history.push("/");
        }
        else{
          //user is not existing..so create a user in our db
          this.props.firebase
                .user(user.uid)
                .set({
                  username : user.displayName,
                  email : user.email,
                  photoUrl : user.photoURL,
                  type: "facebooklogin"
                  
                }).then((u)=>{
                  // console.log("pushing success",u)
                }).catch((error)=>{
                  console.log("pushing error",error)
                })

          this.props.history.push("/");
        }
      })
    }).catch(function (error) {
      console.log("error in facebook", error)
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value, error: false });
  };

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
      errormsg
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <>
      <Form onSubmit={this.onSubmit}>
        <h2 className="mb-3">Signup</h2>
        <Form.Field>
          <label>Username</label>
          <input type="text" name="username" value={username} onChange={this.onChange} placeholder="UserName" />
        </Form.Field>
        <Form.Field>
          <label>Email</label>
          <input type="email" name="email" value={email} onChange={this.onChange} placeholder="Email Address" />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <input type="password" name="passwordOne" value={passwordOne} onChange={this.onChange} placeholder="Password" />
        </Form.Field>
        <Form.Field>
          <label>Confirm Password</label>
          <input type="password" name="passwordTwo" value={passwordTwo} onChange={this.onChange} placeholder="Confirm Password" />
        </Form.Field>
        {/* {isInvalid && <p>Passwords do not match</p>} */}
        <Form.Field>
            <p>By Signing up, you agree to our <a href="/terms" target="_blank" className="text-danger">Terms Conditions</a> & <a href="/privacy" target="_blank" className="text-danger">Privacy Policy</a></p>
        </Form.Field>
        <Button disabled={isInvalid} type='submit' negative fluid>Sign Up</Button>
        {error && <Message>{errormsg}</Message>}
      </Form>
        <Divider />
        <Grid divided='vertically'>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card link onClick={e => this.googleSignIn(e)} centered>
                <Card.Content>
                  <div>
                    <Image src='/images/Google__G__Logo.png' size='mini' avatar />&nbsp;&nbsp;
                    <span>Signin with Google</span>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card link onClick={e => this.facebookSignIn(e)} centered>
                <Card.Content>
                  <div>
                    <Image src='/images/fb.png' size='mini' avatar />&nbsp;&nbsp;
                    <span>Facebook Login</span>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

const SignUpLink = () => (
  <>
    <a className="text-dark" href={ROUTES.SIGN_UP}>Create account</a>
  </>
);

const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };