import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
      };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          var isAdmin =false;
          console.log("authstatechanged",authUser,this.props.firebase.auth)

          //for setting authuser in authuser state and further in authuserContext
          this.props.firebase.users().child(authUser.uid).on('value',snapshot=>{
            console.log("ownn",snapshot.val())
            isAdmin = snapshot.val() ? !!snapshot.val().isAdmin : false
            console.log("inauthentication",authUser)
            authUser
              ? this.setState({ authUser: { ...authUser, isAdmin } })
              : this.setState({ authUser: null });
          })


        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          {console.log("contextt",this.state)}
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;