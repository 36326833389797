import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
var moviesref = null

class AddMovie extends Component {

  constructor(props) {
    super(props);
    this.state = {
      moviename: '',
      synopsis: '',
      language: '',
      genre: '',
      cast: '',
      director: '',
      story: '',
      music: '',
      elements: {
        'acting': 0,
        'story': 0,
        'songs': 0,
        'bgm': 0,
        'comedy': 0,
        'Direction': 0,
        'Cinematography': 0,
        'Action and Fights': 0,
        'Screenplay': 0
      }
    };
  }



  componentDidMount() {


    moviesref = this.props.firebase.movies()
    console.log("addddddd")

  }

  componentDidUpdate() {
    /* ... */
    // console.log("AddmovieUpdating",this.state)
  }




  handleClick = () => {
    //do something
    moviesref.child(this.state.language).push({
      ...this.state,
      'totalvotes': 0,
      'rating': 0
    })
    console.log(this.state);
    this.setState = {
      moviename: '',
      synopsis: '',
      language: '',
      genre: '',
      cast: '',
      director: '',
      story: '',
      music: '',
      elements: {
        'acting': 0,
        'story': 0,
        'songs': 0,
        'bgm': 0,
        'comedy': 0,
        'Direction': 0,
        'Cinematography': 0,
        'Action and Fights': 0,
        'Screenplay': 0
      }
    };

  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <div>
        <br></br><br></br><br></br><br></br>
        <input type="text" placeholder='Moviename' name="moviename" onChange={this.handleChange} />
        <input type="text" placeholder='language' name="language" onChange={this.handleChange} />
        <input type="text" placeholder='Synopsis' name="synopsis" onChange={this.handleChange} />
        <input type="text" placeholder='Genre' name="genre" onChange={this.handleChange} />
        <input type="text" placeholder='Cast' name="cast" onChange={this.handleChange} />
        <input type="text" placeholder='Direction' name="director" onChange={this.handleChange} />
        <input type="text" placeholder='Story' name="story" onChange={this.handleChange} />
        <input type="text" placeholder='Music' name="music" onChange={this.handleChange} />
        <button type="submit" onClick={this.handleClick}>submit</button>
      </div>
    )
  }
}


export default withFirebase(AddMovie);




