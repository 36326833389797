import React from 'react'
import { useState, useEffect } from 'react';
import {
    Container, Header, Label, Grid, Divider, Feed, Rating,
} from 'semantic-ui-react'

const Reviewcard = ({ comment, starrating, selectedelements, timeStamp, movieInfo }) => {
    let elements = Object.keys(selectedelements).map((keyName, i) => {
        return (selectedelements[keyName] === 1 ? [<Label key={i} circular className={keyName}><img src={`/images/${keyName}.svg`} alt={keyName} />&nbsp;{keyName}</Label>, ''] : '')
    })

    let { moviename, imageurl, language, mid } = movieInfo

    return (
        <Feed size='large' style={{ flexGrow: 1, width: "100%" }}>
            <Feed.Event>
                <Feed.Label >
                    <img src={imageurl} style={{ borderRadius: 0 }} />
                </Feed.Label>
                <Feed.Content>
                    <Feed.Summary>
                        <a target="_blank" href={`/${language}/${moviename}/${mid}`}><Feed.User>{moviename}</Feed.User></a>
                        <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
                        <br />
                        <Rating defaultRating={starrating} maxRating={5} disabled />
                    </Feed.Summary>
                    <p>{comment}</p>
                    <Feed.Meta className="movie-elements">
                        {elements}
                    </Feed.Meta>
                </Feed.Content>
            </Feed.Event>
            <Divider />
        </Feed>
    )
}

export default Reviewcard