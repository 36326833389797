import React, { Component } from 'react';

import { Container, Grid, Card, Divider, Segment, Header, Button, Checkbox, Form, Image } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PasswordForgetLink } from './PasswordForget';
import { SignUpLink } from './SignUp';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const SignInPage = () => (
  <Container style={{
    marginTop: 80
  }}>
    <Grid columns='equal'>
      <Grid.Column only='tablet computer'>
        <Image size="medium" src='/images/login_popup.svg' style={{ margin: "0 auto" }} />
      </Grid.Column>
      <Grid.Column className="middle aligned content">
        <SignInForm />
        <Segment clearing basic>
          <Header as='h5' floated='left'>
            <SignUpLink />
          </Header>
          <Header as='h5' floated='right'>
            <PasswordForgetLink />
          </Header>
        </Segment>
      </Grid.Column>
    </Grid>
  </Container>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, persistence: "local" };
  }

  toggleRememberMe = () => {
    var persistence = this.state.persistence === "local" ? "session" : "local";
    this.setState({ persistence })
    this.props.firebase.setPersistence(persistence)
      .then((x) => {
      });
  }

  onSubmit = event => {
    const { email, password } = this.state;
    // console.log("persis", this.props.firebase.auth)
    // this.props.firebase.auth().setPersistence(this.props.firebase.auth.Auth.Persistence.NONE)



    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((authUser) => {
        console.log("loggedin", authUser)
        this.setState({ ...INITIAL_STATE });

        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });


    event.preventDefault();
  };

  googleSignIn = (event) => {

    var app = this.props.firebase.firebaseApp();
    var auth = this.props.firebase.firebaseAuth()
    console.log("google", app)
    var provider = new app.auth.GoogleAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    auth.signInWithPopup(provider).then((result) => {
      var token = result.credential.accessToken;
      var user = result.user;

      this.props.firebase.user(user.uid).on('value', snapshot => {
        let myuser = snapshot.val();
        console.log("signedddinn", myuser)

        if (myuser) {
          //user already created..this is just login
          this.props.history.push("/");
        }
        else {
          //user is not existing..so create a user in our db

          const epoch_time = Math.floor( new Date().getTime()/1000 );
          const welcome_notify_object = {
            'Title' : `Hey ${user.displayName} !! Welcome to Wattamovie, Reviews reinvented by YOU`,
            'Timestamp' : Date().substr(4,20),
            'Type' : 'OPEN_LINK',
            'isOnclickEnabled' : "True",
            'ExtraInfo' : {
              'onClickLink' : 'https://wattamovie.com',
              'imageUrl' : 'https://picsum.photos/200'
            },
            'isSeen':0,
            'clickCounter' : 0,
          }

          const update_bio_notify_object = {
            ...welcome_notify_object,
            'Title' : `Whats your favourite dialogue? Click this to tell the world!!`,
            'ExtraInfo' : {
              'onClickLink' : 'https://wattamovie.com/profile',
              'imageUrl' : 'https://picsum.photos/200'
            },            
          }

          this.props.firebase
            .user(user.uid)
            .set({
              username: user.displayName,
              email: user.email,
              photoUrl: user.photoURL,
              type: "googlelogin",
              fromPage : 'signin',
              notifications : {
                [epoch_time] : welcome_notify_object,
                [epoch_time + 10] : update_bio_notify_object
              }              

            }).then((u) => {
              // console.log("pushing success",u)
            }).catch((error) => {
              console.log("pushing error", error)
            })

          this.props.history.push("/");
        }
      })
    }).catch(function (error) {
      console.log("error in google", error)
    });
  }

  facebookSignIn = (event) => {

    var app = this.props.firebase.firebaseApp();
    var auth = this.props.firebase.firebaseAuth()
    var provider = new app.auth.FacebookAuthProvider()
    // provider.addScope('https://www.googleapis.com/auth/userinfo.email')
    auth.signInWithPopup(provider).then((result) => {
      var token = result.credential.accessToken;
      var user = result.user;
      console.log("facebook", user, user.providerData[0].providerId)

      this.props.firebase.user(user.uid).on('value', snapshot => {
        let myuser = snapshot.val();
        console.log("signedddinn face", myuser)

        if (myuser) {
          //user already created..this is just login
          this.props.history.push("/");
        }
        else {
          //user is not existing..so create a user in our db
          this.props.firebase
            .user(user.uid)
            .set({
              username: user.displayName,
              email: user.email,
              photoUrl: user.photoURL,
              type: "facebooklogin"

            }).then((u) => {
              // console.log("pushing success",u)
            }).catch((error) => {
              console.log("pushing error", error)
            })

          this.props.history.push("/");
        }
      })
    }).catch(function (error) {
      console.log("error in facebook", error)
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <>
        <Form onSubmit={this.onSubmit}>
          <h2 className="mb-3">Signin</h2>
          <Form.Field>
            <label>Email</label>
            <input type="email" name="email" value={email} onChange={this.onChange} placeholder="Email Address" />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input type="password" name="password" value={password} onChange={this.onChange} placeholder="Password" />
          </Form.Field>
          <Form.Field>
            <Checkbox label='Remember me' defaultChecked={true} onChange={() => { this.toggleRememberMe() }} />
          </Form.Field>
          <Button disabled={isInvalid} type='submit' negative fluid>Signin</Button>
          {error && <p>{error.message}</p>}
        </Form>
        <Divider />
        <Grid divided='vertically'>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Card link onClick={e => this.googleSignIn(e)} centered>
                <Card.Content>
                  <div>
                    <Image src='/images/Google__G__Logo.png' size='mini' avatar />&nbsp;&nbsp;
                    <span>Signin with Google</span>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column>
              <Card link onClick={e => this.facebookSignIn(e)} centered>
                <Card.Content>
                  <div>
                    <Image src='/images/fb.png' size='mini' avatar />&nbsp;&nbsp;
                    <span>Facebook Login</span>
                  </div>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };