import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import PasswordChangeForm from './PasswordChange';
import SignOut from './SignOut';
import { useState, useEffect } from 'react';
import {
  Segment, Button,
  Form,
  Radio, Container, Header, Image, Modal, Label, Message, Grid,
} from 'semantic-ui-react'

import { Lottieloading } from '../minicomponents/loadingcomps';

const DelAcc = ({ user, props, setLoading }) => {

  const [isPopupOpen, setPopupOpen] = useState(false)
  const [deleteReason, setdeleteReason] = useState("")
  const [reasonValue, setReasonValue] = useState();
  const [errorMsg, setErrorMsg] = useState("You are about to delete your account from WattaMovie.com permanently.Your reviews will no longer be visible to others")

  const reasons = {
    '1': "Cant find my favourite movie ",
    '2': "Site is boring",
    '3': "Irrelevant and spam reviews",
    '4': "Other Reason"

  }


  const deleteuser = () => {
    let deletedUid = user.uid;
    console.log("user in delete", user, props.firebase.auth.currentUser, props.firebase.auth.currentUser)
    let rootRef = props.firebase.rootref();
    let feedback = document.getElementById("feed").value;
    props.firebase.auth.currentUser.delete().then(() => {

      rootRef.child("users").child(deletedUid).once("value", snapshot => {

        let { ReviewedMovies, email, username } = snapshot.val();
        //get the reviewed movies and for each reviewed movie process this review id as deleteReview() 
        //and store it in deleted reviews
        for (let mid in ReviewedMovies) {
          let rid = ReviewedMovies[mid].reviewid;
          rootRef.once("value", snapshot => {
            let language = snapshot.child("movies/Telugu").hasChild(mid) ? 'Telugu' : 'Tamil'
            let movie = snapshot.child("movies").child(language).child(mid).val();
            let reviewInfo = snapshot.child('AllReviews').child(rid).val();

            const deleteReviewWithId = (rid, uid, movie, reviewInfo) => {

              var { starrating, selectedelements } = reviewInfo;
              var { mid, language, totalvotes, rating, elements } = movie

              var updatedrating = totalvotes - 1 ? ((rating * totalvotes) - starrating) / (totalvotes - 1) : 0
              var updatedElements = elements

              console.log("afterdeleted deletedd", rating, totalvotes, starrating)
              Object.keys(elements).map((elem) => {
                updatedElements[elem] = elements[elem] - selectedelements[elem]
              })
              rootRef.child(`movies/${language}/${mid}/totalvotes`).set(totalvotes - 1)
              rootRef.child(`movies/${language}/${mid}/rating`).set(updatedrating)
              rootRef.child(`movies/${language}/${mid}/elements`).set(updatedElements)
              rootRef.child(`movies/${language}/${mid}/reviews/${uid}`).set(null)
              rootRef.child(`users/${uid}/ReviewedMovies/${mid}`).set(null)
              rootRef.child("deletedreviews").push(rid)
            }
            deleteReviewWithId(rid, deletedUid, movie, reviewInfo);
          })
        }


        rootRef.child(`users/${deletedUid}`).set(null)
        let box = { email, username, deleteReason, feedback };
        rootRef.child("deletedusers").child(deletedUid).set(box)

      })
    })
      .catch(function (error) {
        console.log("unable to delete account", error)
        setErrorMsg(error.message)
        if (error.code === "auth/requires-recent-login") {
          setLoading(true);
          setTimeout(() => { props.firebase.doSignOut() }, 3000)
        }
      });
  }

  const handleChange = (e, { value }) => {
    setReasonValue(value)
    setdeleteReason(reasons[value])
    setErrorMsg("")
  }

  const ConfirmDeletePopup = () => {
    return (
      <Modal closeIcon open={isPopupOpen} onClose={() => { setPopupOpen(false) }} >
        <Header icon='lock' content='Account Deletion' />
        <Modal.Content>

          {errorMsg ? <Message warning >{errorMsg}</Message> : <></>}
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Image size='medium' src='/images/missyou.png' />
              </Grid.Column>
              <Grid.Column width={10}>
                <Form>
                  <Label>Tell us why do you want to delete your account</Label>
                  <Form.Group grouped>
                    <Segment><Form.Field control={Radio} label={reasons['1']} value='1' checked={reasonValue === '1'} onChange={handleChange} /></Segment>
                    <Segment><Form.Field control={Radio} label={reasons['2']} value='2' checked={reasonValue === '2'} onChange={handleChange} /></Segment>
                    <Segment><Form.Field control={Radio} label={reasons['3']} value='3' checked={reasonValue === '3'} onChange={handleChange} /></Segment>
                    <Form.TextArea label='Other' id="feed" placeholder='Tell us more about it' />
                  </Form.Group>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid.Row>
            <Grid.Column width={16}>
            </Grid.Column>
          </Grid.Row>

        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => { setPopupOpen(false) }}>Cancel</Button>
          <Button color="red" onClick={() => { deleteReason ? deleteuser() : setErrorMsg('Please select a reason') }}>Delete Account</Button>
        </Modal.Actions>
      </Modal>
    )

  }

  return (
    <Form>
      <Form.Group inline>
        <a href="javascript:void(0)" value={"user"} onClick={() => { setPopupOpen(!isPopupOpen) }}>Permanently Delete My Account</a>
      </Form.Group>
      <ConfirmDeletePopup />
    </Form>
  )
}

const AccountPage = (props) => {
 console.log("Accounttttt",props)

  return (
    <Container style={{
      marginTop: 55
    }}>
      {/* {loading && <Lottieloading />} */}
      <AuthUserContext.Consumer>
        {authUser => (
          <>
            <UserAccount props={{...props,authUser}}/>
          </>
        )}
      </AuthUserContext.Consumer>
    </Container>
  )
}

const UserAccount = ({props}) =>{
  const [loading, setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState(null)
  const [bio,setBio] = useState('')
  const authUser = props.authUser
  useEffect(() => {
    setLoading(false)
    //load bio from user details
    let rootRef = props.firebase.rootref();
    console.log(props)
    rootRef.child("users").child(authUser.uid).on('value',snapshot=>{
      setBio(snapshot.val().bio)
    })
    // setBio(bio)
  }, [])

  const pushBio = (user) => {
    console.log(bio,props,user)
    let rootRef = props.firebase.rootref();
    console.log()
    rootRef.child("users").child(user.uid).child('bio').set(bio)
  }
  return(
          <>
            <Grid divided='vertically'>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h1'>Account Settings</Header>
                </Grid.Column>
                <Grid.Column>
                  <img width={"50px"} style={{borderRadius:"10px"}} src={!authUser.photoURL ? authUser.photoURL : '/images/user_icon.png'}></img>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Username</Header>
                </Grid.Column>
                <Grid.Column>
                  <h4>{authUser.displayName}</h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Email</Header>
                </Grid.Column>
                <Grid.Column>
                  <h4>{authUser.email}</h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Password Change</Header>
                </Grid.Column>
                <Grid.Column>
                  <PasswordChangeForm setErrorMsg={setErrorMsg} />
                  {errorMsg && <Label color="red">{errorMsg}</Label>}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Bio</Header>
                </Grid.Column>
                <Grid.Column>
                  <Form >
                    <Form.Group inline>
                      <Form.Field>
                        <Form.Input name="Bio" type="text" value={bio} onChange={(e)=>{setBio(e.target.value)}} placeholder="Write a short Bio" />
                      </Form.Field>
                      <Form.Field>
                        <Form.Button content='Submit' onClick={()=>{pushBio(authUser)}} type="submit" />
                      </Form.Field>
                    </Form.Group>
                  </Form>  
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Account Delete</Header>
                </Grid.Column>
                <Grid.Column>
                  <DelAcc user={authUser} props={props} setLoading={setLoading} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Header as='h3'>Logout</Header>
                </Grid.Column>
                <Grid.Column>
                  <SignOut />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column textAlign='right'>
                  <a href="/terms" target="_blank" className="text-danger">Terms Conditions</a>
                </Grid.Column>
                <Grid.Column textAlign='left'>
                  <a href="/privacy" target="_blank" className="text-danger">Privacy Policy</a>
                </Grid.Column>
              </Grid.Row>
            </Grid>    
          </>
  )
}



const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);

