import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react'

export default function MenuAppBar() {
    return (
        <>
            <Container style={{
                marginTop: 55
            }}>
                <Grid divided='vertically'>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h1'>Terms & Conditions</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2'>ACCEPTANCE OF TERMS</Header>
                            <p>These Terms of Use set forth the terms and conditions that apply to your use of the Services. By using the Services, you agree that you have read, understand, and agree to be legally bound by these Terms.
                        Watta Movie may, subject to the below, change these Terms from time to time on a prospective basis, and modify, add or discontinue any aspect, content, or feature of the Services, at its sole discretion.</p>
                            <Header as='h2'>REGISTRATION, ACCOUNTS, AND PASSWORDS</Header>
                            <p>If you establish a personal account with us, you agree to provide true and accurate data about yourself on our account registration process. You are solely responsible for maintaining the confidentiality of your password and account, and you are solely responsible for all use of your password or account, whether authorized by you or not. You shall not allow other persons access to or use of such username or password. You shall not post your username or password on any website nor transmit it through unsecured sites. You agree to (a) immediately notify Watta Movie of any unauthorized use of your password or account or any other breach of security and (b) ensure that you exit from your account each time you use the Services. We may terminate your account and access to the Services if we learn that you have provided us with false or misleading registration data. If we feel your username and password are insecure or otherwise problematic, we may require you to change it or terminate your account.</p>
                            <Header as='h2'>USER RESPONSIBILITY AND CONDUCT</Header>
                            <p>By signing up to Watta Movie you agree not to do the following, or assist others to do the following:</p>
                            <ul>
                                <li>Usage of abusive & sexually explicit words</li>
                                <li>Does or may threaten, abuse, harass, or invade the privacy of other users</li>
                                <li>Spamming with advertisements or promote goods or services</li>
                                <li>Impersonating others by creating fake accounts</li>
                                <li>Trying to Manipulate the accuracy and integrity of ratings or reviews</li>
                                <li>Transmit files that contain viruses, spyware, adware, or other harmful code</li>
                                <li>Attempt to gain unauthorized access to other computer systems or networks connected to the Services</li>
                                <li>Interfere with any other user from using and enjoying the Services</li>
                                <li>Collect information about other users or use any such information for unsolicited advertising or any other form of unauthorized solicitation.</li>
                            </ul>
                            <p>If you violate this Agreement, we may terminate your access to Watta Movie without notice, and take any other actions or seek any remedies permitted by law.</p>
                            <Header as='h2'>DISCLAIMER</Header>
                            <p>While Watta Movie uses reasonable efforts to include up to date information on the Site, Watta Movie makes no warranties or representations as to its accuracy, timeliness, reliability, completeness, or otherwise. Meanwhile, you can reach out to us regarding any misinformation or inaccurate data about movies. <br /><br />Updated on 08/01/2021</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
}
