import React from 'react';
import { Menu, Image, Dropdown, Responsive, Icon, Label } from 'semantic-ui-react'
import SignOutButton from './SignOut';
import { AuthUserContext } from '../Session';
import { useState, useEffect } from 'react';


export default function MenuAppBar({ data }) {
  const { notifCount } = data

  return (
    <Menu fixed='top' borderless size='huge' style={{ justifyContent: "space-between" }}>
      <Menu.Item href="/" header>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Image size='small' src='/logo.png' />
        </Responsive>
        <Responsive {...Responsive.onlyMobile}>
          <Image size='mini' src='/favicon.png' />
        </Responsive>
      </Menu.Item>
      <div style={{ display: "flex" }}>
        <Menu.Item className={window.location.pathname === '/Tamil' ? 'active' : undefined} href="/Tamil" name='Kollywood' />

        <Menu.Item className={window.location.pathname === '/Telugu' ? 'active' : undefined} href="/Telugu" name='Tollywood' />
      </div>
      <div style={{ display: "flex", alignItems: "stretch" }}>
        <AuthUserContext.Consumer>
          {authUser => authUser &&
            (<Menu.Item
              className={window.location.pathname === '/notifications' ? 'active' : undefined}
              href="/notifications" name='notifications'>
              <Icon.Group>
                <Icon name='bell' color='grey' />
                <Icon corner='top right' className='notification-circle' color="white" name=''>{notifCount}</Icon>
              </Icon.Group>
            </Menu.Item>)
          }
        </AuthUserContext.Consumer>
        <Menu.Menu>
          <Dropdown item simple icon='user' style={{ color: "#808080", height: "100%" }}>
            <AuthUserContext.Consumer>
              {authUser =>
                authUser
                  ? (<Dropdown.Menu>
                    {/* <Dropdown.Item href="/account">My Account</Dropdown.Item> */}
                    <Dropdown.Item href="/profile">My Profile</Dropdown.Item>
                    <SignOutButton />
                  </Dropdown.Menu>)
                  : (<Dropdown.Menu right="true">
                    <Dropdown.Item href="/signin">Signin</Dropdown.Item>
                    <Dropdown.Item href="/signup">Signup</Dropdown.Item>
                  </Dropdown.Menu>)
              }
            </AuthUserContext.Consumer>
          </Dropdown>
        </Menu.Menu>
      </div>
    </Menu>
  );
}

