import React from 'react';
import { Dropdown } from 'semantic-ui-react'
import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
  <Dropdown.Item href="/" onClick={firebase.doSignOut}>
    Sign Out
  </Dropdown.Item>
);

export default withFirebase(SignOutButton);