import React from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import { useState, useEffect } from 'react';
import {
    Container, Header, Label, Grid, Divider, Feed, Rating, 
} from 'semantic-ui-react'

import { Lottieloading } from '../minicomponents/loadingcomps';

const AccountPage = (props) => {
    const [loading, setLoading] = useState(true)
    const [] = useState(null)
    console.log("props in account",props)
    useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <Container style={{
            marginTop: 55
        }}>
            {loading && <Lottieloading />}
            <AuthUserContext.Consumer>
                {authUser => (
                    <>
                        {console.log("myuser", authUser)}
                        <Grid divided='vertically'>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <Header as='h1'>My Activity</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column className="activity">
                                    <ActivityTab firebase={props.firebase} authUser={authUser} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>
                )}
            </AuthUserContext.Consumer>
        </Container>
    )
}


const ActivityTab = ({ firebase, authUser }) => {

    const [myReviews, setmyReviews] = useState([])

    useEffect(() => {
        console.log("myrevs", myReviews);
    }, [myReviews])

    useEffect(() => {
        firebase.users().on('value', snap => {
            console.log("testing", Object.keys(snap.val()).length, snap.val())
        })

        firebase.user(authUser.uid).on('value', snapshot => {
            console.log("userinfo", snapshot.val())

            let reviewedMovies = snapshot.val().ReviewedMovies;

            for (let mid in reviewedMovies) {
                let lang = reviewedMovies[mid]["Language"]
                let rid = reviewedMovies[mid]["reviewid"]
                firebase.movies().child(lang).child(mid).once('value', snapshot => {
                    // console.log("moviee",snapshot.val())
                    let movieInfo = snapshot.val();

                    firebase.rootref().child("AllReviews").child(rid).once('value', snapshot => {
                        let reviewInfo = snapshot.val()
                        console.log("eachreview", rid, reviewInfo, movieInfo["moviename"])
                        let eachReview = { ...reviewInfo, movieInfo }
                        setmyReviews(myReviews => { return [...myReviews, eachReview] })
                    })

                })
            }



        })

    }, [firebase])

    return (
        <>
            {myReviews.map(rev => {
                var { comment, userName, selectedelements, starrating, timeStamp, movieInfo } = rev

                return (
                    <>
                        <Reviewcard comment={comment} userName={userName} starrating={starrating} selectedelements={selectedelements} timeStamp={timeStamp} movieInfo={movieInfo} />
                    </>
                )
            })}
        </>)
}

const Reviewcard = ({ comment, starrating, selectedelements, timeStamp, movieInfo }) => {
    let elements = Object.keys(selectedelements).map((keyName, i) => {
        return (selectedelements[keyName] === 1 ? [<Label key={i} circular className={keyName}><img src={`/images/${keyName}.svg`} alt={keyName}/>&nbsp;{keyName}</Label>, ''] : '')
    })

    let { moviename, imageurl, language, mid } = movieInfo

    return (
        <Feed size='large'>
            <Feed.Event>
                <Feed.Label >
                    <img src={imageurl} style={{ borderRadius: 0 }} />
                </Feed.Label>
                <Feed.Content>
                    <Feed.Summary>
                        <a target="_blank" href={`/${language}/${moviename}/${mid}`}><Feed.User>{moviename}</Feed.User></a>
                        <Feed.Date>{timeStamp.slice(4, 15)}</Feed.Date>
                        <br />
                        <Rating defaultRating={starrating} maxRating={5} disabled />
                    </Feed.Summary>
                    <p>{comment}</p>
                    <Feed.Meta className="movie-elements">
                        {elements}
                    </Feed.Meta>
                </Feed.Content>
            </Feed.Event>
            <Divider />
        </Feed>
    )
}



const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);


